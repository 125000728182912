import { ESituacao, EUnidadeTempo } from 'enums';

export class Setor {
	setorID: number;
	nome: string;
	situacaoID: ESituacao;
	usuarioIDCadastro?: number;
	usuarioNomeCadastro?: string;
	dataHoraCadastro?: string;
	usuarioIDAlteracao?: number;
	usuarioNomeAlteracao?: string;
	dataHoraAlteracao?: string;
	setorEmpresaID: number;

	constructor(dto?: any) {
		this.setorID = dto?.setorID;
		this.nome = dto?.nome || dto?.setorNome;
		this.situacaoID = dto?.situacaoID as ESituacao;
		this.usuarioIDCadastro = dto?.usuarioIDCadastro;
		this.usuarioNomeCadastro = dto?.usuarioNomeCadastro;
		this.dataHoraCadastro = dto?.dataHoraCadastro;
		this.usuarioIDAlteracao = dto?.usuarioIDAlteracao;
		this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
		this.dataHoraAlteracao = dto?.dataHoraAlteracao;
		this.setorEmpresaID = dto?.setorEmpresaID;
	}
}