import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';

import { DarkTheme, LightTheme } from 'shared/themes';
import CssBaseline from '@mui/material/CssBaseline';
import { TemaProvider } from './ThemaContext';

interface IThemeContextData {
	themeName: 'light' | 'dark';
	toggleTheme: () => void;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
	return useContext(ThemeContext);
};

interface IThemeProviderProps {
	children: React.ReactNode;
}
export const AppThemeProvider: React.FC<IThemeProviderProps> = ({ children }) => {
	const [themeName, setThemeName] = useState<'light' | 'dark'>('light');

	const toggleTheme = useCallback(() => {
		setThemeName(oldThemeName => oldThemeName === 'light' ? 'dark' : 'light');
	}, []);

	const theme = useMemo(() => {
		if (themeName === 'light') return LightTheme;

		return DarkTheme;

	}, [themeName]);

	return (
		<ThemeContext.Provider value={{ themeName, toggleTheme }}>
			<TemaProvider>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Box width='100vw' height='100vh' bgcolor={theme.palette.background.default}>
						{children}
					</Box>
				</ThemeProvider>
			</TemaProvider>
		</ThemeContext.Provider >
	);
};