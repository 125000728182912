import { Button, Card, CircularProgress, Stack, Typography, Icon } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useDebounce } from 'shared/hooks';
import { IListagemEmpresa, EmpresaService } from '../services/Empresas/EmpresasService';
import { useAutenticacaoContext, useEmpresaSelecionadaContext } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { Empresa } from 'models';
import { CircularProgressCustom } from 'shared/components';

interface ILayoutBaseDePaginaProps {
	children: React.ReactNode;
	ferramentasDaListagem?: ReactNode | undefined;
	barraDeFerramentas?: ReactNode | undefined;
}

export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({ children, barraDeFerramentas: barraDeFerramentas, ferramentasDaListagem: ferramentasDaListagem }) => {

	const { debounce } = useDebounce(300, false);
	const [isLoadingEmpresas, setIsLoadingEmpresas] = useState(true);
	const [rowsEmpresas, setRowsEmpresas] = useState<Empresa[]>([]);
	const navigate = useNavigate();
	const { handleLogout } = useAutenticacaoContext();
	const { empresaSelecionada } = useEmpresaSelecionadaContext();

	useEffect(() => {
		debounce(() => {
			if (isLoadingEmpresas) {
				from(EmpresaService.getAll())
					.pipe(
						tap(() => setIsLoadingEmpresas(false)),
						switchMap((retorno: Empresa[] | Error) => {
							if (retorno instanceof Error)
								return throwError(() => retorno);
							return of(retorno.map((empresa: any) => new Empresa(empresa)));
						})
					)
					.subscribe({
						next: (empresas: Empresa[]) => setRowsEmpresas(empresas),
						error: (error) => console.log(error)
					});
			}
		});
	});
	return (
		<Box
			display='flex'
			flexDirection='column'
			width='99vw'
			minHeight='100vh'>
			<Stack
				direction={'row'}
				spacing={50}
				bgcolor='#085784'
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='5vh'>
				<Box position='absolute'>
					<img src='/Logo-arquivoteca-para-footer-min.png' height={28} style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
				</Box>
				<Box flex={1} display='flex' justifyContent='end'>
					<Button
						style={{
							fontSize: '15px',
							color: 'white',
							display: 'flex'
						}}
						disableElevation
						variant='outlined'
						size='small'
						onClick={() => { handleLogout(); window.location.reload(); }}
						endIcon={<Icon>logout</Icon>}
					// onClick={() => { window.location.href = 'https://www.ecmhorus.com.br'; }}
					// >Ir para o Site</Button>
					>Sair</Button>
				</Box>
			</Stack>

			<Box paddingX={1} paddingTop={1} sx={{ backgroundColor: '#D3D3D3' }}>
				<Card variant='outlined' sx={{
					boxShadow: 1,
					borderRadius: 1,
					p: 1,
					minWidth: 150,
					maxWidth: 250
				}} >
					<CircularProgressCustom loadding={isLoadingEmpresas}>
						<Typography key={0} fontSize={10} align='left' color='#085784' fontWeight='bold'>
							Empresa: {empresaSelecionada?.nomeFantasia}
						</Typography>
					</CircularProgressCustom>
				</Card>
			</Box>

			{
				ferramentasDaListagem && (
					<Typography>
						<Box>
							{ferramentasDaListagem}
						</Box>
					</Typography>
				)
			}

			{
				barraDeFerramentas && (
					<Typography>
						<Box>
							{barraDeFerramentas}
						</Box>
					</Typography>
				)
			}

			<Typography>
				<Box>
					{children}
				</Box>
			</Typography>
		</Box >
	);
};