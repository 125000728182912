import { FC, useCallback, useMemo, useRef } from 'react';
import './App.css';

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Api } from 'shared/services/Axios';
import { from, of, switchMap, throwError } from 'rxjs';
import { useEmpresaSelecionadaContext } from 'contexts';
import { Documento } from 'models';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface IFileUpload {
	tipologiaId: string;
	documentoEnviadoSucesso: (documentos: any[]) => void;
	documentoEnviadoErro: (erro: any, documento: any) => void;
	enviandoAquivo: (documentos: any[]) => void;
}

export const FileUpload: FC<IFileUpload> = ({ tipologiaId, documentoEnviadoSucesso, documentoEnviadoErro, enviandoAquivo }) => {
	const { empresaSelecionada } = useEmpresaSelecionadaContext();

	const empresaId = useMemo(() => empresaSelecionada?.empresaID + '', [empresaSelecionada]);

	const files = useRef<Documento[]>([]);

	const getFile = useCallback((fileOrId: string | any): Documento => {
		if (typeof fileOrId === 'string') {
			const documento = files.current.find((item) => item.documentoID == fileOrId);
			if (documento)
				return documento;
		}
		const documentos = pond.current?.getFiles() || [];
		const documento = documentos.find((item) => item.file == fileOrId);
		return new Documento(documento);
	}, []);
	const setFile = useCallback((file: Documento) => files.current.push(file), []);
	const removeFile = useCallback((file: Documento) => files.current = files.current.filter(({ documentoID }) => documentoID != file.documentoID), []);

	const pond = useRef<FilePond | null>();

	const handleInit = () => console.log('FilePond instance has initialised', pond.current);

	return (
		<FilePond
			ref={ref => pond.current = ref}
			//files={files}
			maxFiles={100}
			allowPaste={true}
			allowMultiple={true}
			allowReplace={false}
			allowReorder={false}
			allowProcess={true}
			allowRevert={true}
			credits={false}
			labelIdle='Arraste e solte documentos aqui ou clique para selecionar!'
			labelInvalidField='Documentos inválidos'
			labelFileWaitingForSize='Calculando o tamanho do documento'
			labelFileSizeNotAvailable='Tamanho do documento indisponível'
			labelFileLoading='Carregando'
			labelFileLoadError='Erro durante o carregamento'
			labelFileProcessing='Enviando'
			labelFileProcessingComplete='Envio finalizado'
			labelFileProcessingAborted='Envio cancelado'
			labelFileProcessingError='Erro durante o envio'
			labelFileProcessingRevertError='Erro ao reverter o envio'
			labelFileRemoveError='Erro ao remover o documento'
			labelTapToCancel='clique para cancelar'
			labelTapToRetry='clique para reenviar'
			labelTapToUndo='' // clique para desfazer
			labelButtonRemoveItem='' // Remover
			labelButtonAbortItemLoad='Abortar'
			labelButtonRetryItemLoad='Reenviar'
			labelButtonAbortItemProcessing='Cancelar'
			labelButtonUndoItemProcessing='' // Desfazer
			labelButtonRetryItemProcessing='Reenviar'
			labelButtonProcessItem='Enviar'
			styleLoadIndicatorPosition='center'
			server={{
				timeout: 7000,
				process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
					const reader = new FileReader();
					reader.addEventListener(
						'load',
						() => {
							const base64 = reader.result!.toString().replace('data:application/pdf;base64,', '');
							const blob = new Blob([base64], { type: 'application/octet-stream' });
							const formData = new FormData();
							formData.append('PDF', blob, file.name);
							formData.append('TipologiaID', tipologiaId);
							formData.append('EmpresaID', empresaId);
							formData.append('DocumentoNome', file.name);
							from(Api.post('/Documento/UploadFile', formData, {
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							}))
								.pipe(
									switchMap((response) => {
										if (response.data)
											return of(response.data.data);

										return throwError(() => 'oh no');
									})
								)
								.subscribe({
									next: (response) => {
										const documento = getFile(file);
										setFile({ ...documento, ...response });
										documentoEnviadoSucesso(files.current);
										load(response);
									},
									error: (erro) => error(erro),
								});
						},
						false,
					);
					reader.readAsDataURL(file);

					return {
						abort: () => abort(),
					};
				}
			}}
			oninit={() => handleInit()}
			onerror={(error, file, status) => documentoEnviadoErro(error, file)}
			onprocessfilerevert={({ id }) => {
				const documento = getFile(id);
				const { documentoEletronicoID, hashNome } = documento;
				from(Api.post('/Documento/DeleteFile', { DocumentoEletronicoID: documentoEletronicoID, HashNome: hashNome }))
					.pipe(
						switchMap((response) => {
							if (response.data)
								return of(response.data.data);

							return throwError(() => 'oh no');
						})
					)
					.subscribe({
						next: (response) => {
							removeFile(documento);
							documentoEnviadoSucesso(files.current);
						},
						error: (erro) => console.log(erro),
					});
			}}
		/>
	);
};

export default FileUpload;