import { useCallback, useEffect, useState } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';

interface IMenuContext {
	collapsed: boolean;
	image: boolean;
	toggled: boolean;
	toggleMenu: () => void;
	toggleCollapse: () => void;
	toggleImage: () => void;
}

const MenuContext = createContext<IMenuContext | any>({});

interface IMenuProviderProps {
	children: React.ReactNode;
}

export const MenuProvider: React.FC<IMenuProviderProps> = ({ children }) => {
	const [collapsed, setCollapsed] = useState(true);
	const [image, setImage] = useState(false);
	const [toggled, setToggled] = useState(false);

	useEffect(() => {
		const localCollapsed = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STATUS_MENU!)!);
		setCollapsed(localCollapsed);
	}, []);

	useEffect(() => {
		localStorage.setItem(process.env.REACT_APP_LOCAL_STATUS_MENU!, JSON.stringify(collapsed));
	}, [collapsed]);

	const toggleMenu = useCallback(() => {
		setToggled(value => !value);
	}, []);

	const toggleCollapse = useCallback(() => {
		setCollapsed(value => !value);
	}, []);

	const toggleImage = useCallback(() => {
		setImage(value => !value);
	}, []);

	return (
		<MenuContext.Provider value={{ collapsed, image, toggled, toggleMenu, toggleCollapse, toggleImage }}>
			{children}
		</MenuContext.Provider>
	);
};

interface IToggleMenuContext { toggled: boolean; toggleMenu: () => void; }
export const useToggleMenuContext = (): IToggleMenuContext => useContextSelector<IMenuContext, IToggleMenuContext>(MenuContext, ({ toggled, toggleMenu }) => ({ toggled, toggleMenu }));
interface ICollapseMenuContext { collapsed: boolean; toggleCollapse: () => void; }
export const useCollapseMenuContext = (): ICollapseMenuContext => useContextSelector<IMenuContext, ICollapseMenuContext>(MenuContext, ({ collapsed, toggleCollapse }) => ({ collapsed, toggleCollapse }));
interface IImageMenuContext { image: boolean; toggleImage: () => void; }
export const useImageMenuContext = (): IImageMenuContext => useContextSelector<IMenuContext, IImageMenuContext>(MenuContext, ({ image, toggleImage }) => ({ image, toggleImage }));
