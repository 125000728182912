import React, { useEffect, useMemo, useState } from 'react';
import {
	Paper,
	Box,
	Typography,
	Icon,
	Button,
	TextField,
	TextFieldVariants
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { DadosFormulario, ValidadorSetor } from 'validators/Setor';
import { useDebounce } from 'shared/hooks';
import { Validacao } from 'validators/Validador';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { useEspera, useMensagem } from 'contexts';
import { SetorService } from 'shared/services/Setores/SetoresService';
import { SetorRequest } from 'requests/SetorRequest';

import { ToastErro, ToastSucesso } from 'utils/toast';
import { FormGrid } from 'shared/components';

export const AlterarSetor: React.FC = () => {
	const { state } = useLocation();
	const { setorID } = state;

	const { debounce } = useDebounce(300, false);
	const navigate = useNavigate();

	const [formulario, setFormulario] = useState<DadosFormulario>({
		nome: ''
	});

	const [validacao, setValidacao] = useState<any>({
		nome: { valido: true, mensagem: null } as Validacao
	});

	const { Informacao } = useMensagem();
	const { BloquearTela, DesbloquearTela } = useEspera();

	const _set = (obj: any, validar: boolean = false) => {
		setFormulario((form: DadosFormulario) => ({ ...form, ...obj } as DadosFormulario));
	};

	const variant = useMemo<TextFieldVariants>(() => 'standard', []);


	const handleNome = (event: { target: { value: string }; }) => _set({ nome: event.target.value });

	const handleValidarCampo = (campo: keyof DadosFormulario) => {
		setValidacao((validados: any) => ({ ...validados, ...ValidadorSetor.validarCampo(formulario[campo], campo) }));
	};

	const CadastrarSetor = () => {
		const camposValidados = ValidadorSetor.validar(formulario);
		setValidacao(camposValidados);

		if (ValidadorSetor.ehValido(camposValidados)) {

			Informacao(
				null,
				() => <Typography display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
					Deseja prosseguir com o cadastro da setor?
					<Typography fontWeight={600} fontSize={13} fontStyle='italic'>* Antes de confirmar, revise os dados informados.</Typography>
				</Typography>,
				[
					{
						label: 'Sim',
						onPress: () => {
							const alteracaoSetor = new SetorRequest({
								setorID: setorID,
								nome: formulario.nome
							});

							BloquearTela();
							from(SetorService.update(alteracaoSetor))
								.pipe(
									tap(() => DesbloquearTela()),
									switchMap((resposta) => {
										if (resposta instanceof Error)
											return throwError(() => resposta.message);
										return of(resposta);
									})
								)
								.subscribe({
									next: () => ToastSucesso(`Setor alterada com sucesso`),
									error: (erro) => ToastErro(erro),
									complete: () => navigate('/setor')
								});
						}
					},
					{
						label: 'Não',
						fechar: true
					}
				]
			);
		}
	};

	useEffect(() => {
		debounce(() => {
			BloquearTela();
			from(SetorService.getById(setorID))
				.pipe(
					switchMap((resposta) => {
						if (resposta instanceof Error)
							return throwError(() => resposta.message);
						console.log(resposta);
						return of({
							nome: resposta.nome
						});
					}),
					tap(() => DesbloquearTela()),
				).subscribe({
					next: (formulario) => setFormulario(formulario),
					error: (erro) => ToastErro(erro)
				});
		});
	}, []);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Cadastrar Setor
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<FormGrid size={{ xs: 12 }}>
							<TextField
								id='nome'
								name='nome'
								type='text'
								placeholder='Nome da Setor'
								autoComplete='Nome'
								required
								value={formulario.nome}
								onChange={handleNome}
								label='Nome'
								helperText={validacao.nome.mensagem}
								error={!validacao.nome.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('nome')}
								size='small'
							/>
						</FormGrid>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate('/setor')}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
				<Button
					color='success'
					disableElevation
					variant='contained'
					onClick={() => CadastrarSetor()}
					startIcon={<Icon>save</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						salvar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};
