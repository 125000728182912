import React, { useState, useImperativeHandle, forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from 'react';
import {
	Modal,
	Box,
	Typography,
	Button,
	Checkbox,
	FormControlLabel,
	Backdrop,
	ButtonPropsColorOverrides,
	Divider,
} from '@mui/material';
import Icon from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import { OverridableStringUnion } from '@mui/types';
import { useTema } from 'contexts';

const ETipoMensagem = {
	ATENCAO: 0,
	SUCESSO: 1,
	ERRO: 2,
	INFO: 3,
	PADRAO: 4,
	AGUARDE: 5,
};

export interface IPopUp {
	abrirMensagem: (opcoes: IOpcoesPopUp) => void;
	fecharMensagem: () => void;
}

export interface IOpcoesPopUp {
	tipo: number;
	titulo?: string | null;
	mensagem?: string | null;
	tempo: number | null;
	botoes?: IBotoesPopUp[];
	checkbox?: ICheckboxPopUp | null;
	funcaoFinal?: (() => void) | null;
	componente?: (() => JSX.Element) | null | undefined;
	obrigatoria?: boolean;
}

export interface IBotoesPopUp {
	label?: string | null;
	icon?: string | null;
	fechar?: boolean;
	onPress?: ((item?: boolean) => void) | null;
	color?: OverridableStringUnion<
		'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
		ButtonPropsColorOverrides
	>;
	style?: any | null;
	checkbox?: any | null;
}

export interface ICheckboxPopUp {
	valorInicial: boolean;
	texto: string;
}

const tipoConfig: {
	cor: OverridableStringUnion<
		'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
		ButtonPropsColorOverrides
	>;
	icone: string | null;
}[] = [
		{ cor: 'warning', icone: 'ErrorOutline' },
		{ cor: 'success', icone: 'CheckCircleOutline' },
		{ cor: 'error', icone: 'HighlightOff' },
		{ cor: 'info', icone: 'InfoOutlined' },
		{ cor: 'primary', icone: null },
		{ cor: 'secondary', icone: null },
		{ cor: 'inherit', icone: null },
	];

export const PopUp: ForwardRefExoticComponent<Omit<any, 'ref'> & RefAttributes<any>> = forwardRef((_, ref) => {
	const [opcoes, setOpcoes] = useState<IOpcoesPopUp | null>(null);
	const [valorCheckbox, setValorCheckbox] = useState<boolean>(false);
	const [valor, setValor] = useState<boolean>(false);


	const {
		cores: { INFO, GRAFITE, ERRO, ATENCAO, FUNDO, CONTRASTE, PRIMARIA },
		tamanhoFontes: { FONTE_MEDIA, FONTE_GRANDE, FONTE_SUPER, FONTE_MEGA }
	} = useTema();

	useImperativeHandle(ref, () => ({
		abrirMensagem: (opcoes: IOpcoesPopUp) => {
			setOpcoes({ ...defaultConfig, ...opcoes });
			if (opcoes?.checkbox) {
				setValorCheckbox(opcoes.checkbox.valorInicial);
			}
		},
		fecharMensagem: fecharMensagem,
	}));

	useEffect(() => {
		if (!opcoes) return;

		if (opcoes?.checkbox)
			setValor(opcoes.checkbox?.valorInicial ?? false);
	}, [opcoes]);

	const fecharMensagem = () => setOpcoes(null);

	const defaultConfig: IOpcoesPopUp = {
		tipo: ETipoMensagem.PADRAO,
		titulo: null,
		mensagem: null,
		tempo: 2000,
		botoes: [],
		checkbox: null,
		funcaoFinal: null,
		obrigatoria: true,
	};

	useEffect(() => {
		if (opcoes?.tempo && opcoes.tipo !== ETipoMensagem.AGUARDE) {
			const timer = setTimeout(() => {
				opcoes.funcaoFinal?.();
				setOpcoes(null);
			}, opcoes.tempo);
			return () => clearTimeout(timer);
		}
	}, [opcoes]);

	if (!opcoes) return null;

	const temCheckbox = opcoes?.checkbox != null;
	const exibir = opcoes !== null;
	const tipo = tipoConfig[opcoes.tipo];

	const botaoBase: IBotoesPopUp = {
		label: '',
		icon: null,
		onPress: () => { },
		color: tipo.cor,
		fechar: false,
		style: {},
		checkbox: null
	};

	return (
		<Modal
			open={exibir}
			onClose={() => {
				if (!opcoes.obrigatoria) setOpcoes(null);
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
					minWidth: 300,
					padding: '20px'
				}}
			>
				{opcoes.titulo && (
					<>
						<Typography variant='h6' color={tipo.cor} gutterBottom>
							{opcoes.titulo}
						</Typography>
						<Divider sx={{ mt: .5, mb: 2 }} />
					</>
				)}

				{(opcoes.mensagem || opcoes.componente) && (
					<Typography variant='body1' mb={2}>
						{opcoes.mensagem || opcoes.componente?.() || null}
					</Typography>
				)}

				{opcoes.checkbox && (
					<FormControlLabel
						control={
							<Checkbox
								checked={valorCheckbox}
								onChange={() => setValorCheckbox(!valorCheckbox)}
								color='primary'
							/>
						}
						label={opcoes.checkbox.texto}
					/>
				)}

				{opcoes.tipo === ETipoMensagem.AGUARDE && <LinearProgress />}

				{(opcoes.botoes || []).length > 0 && (
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
						{opcoes.botoes!.map((botao, index) => {
							let configBotao: IBotoesPopUp = {} as IBotoesPopUp;

							if (typeof botao === 'object') {
								botao.color = botao.fechar ? 'error' : tipo.cor;
								configBotao = { ...botaoBase, ...botao };
							}

							if (typeof botao === 'string')
								configBotao = { ...botaoBase, label: botao };

							const Label = () => {
								if (configBotao.label)
									return <Typography sx={[{
										color: FUNDO,
										fontSize: FONTE_MEDIA,
									}, ...configBotao.style]}>{configBotao.label}</Typography>;
								return null;
							};

							const Icone = () => {
								if (configBotao.icon) {
									const Componente = configBotao.icon;
									return (
										<Box style={{ justifyContent: 'center', alignItems: 'center' }}>
											<Componente />
											<Label />
										</Box>
									);
								}
								return <Label />;
							};

							return (
								<Button
									key={index}
									variant='contained'
									color={botao.color || 'primary'}
									onClick={() => {
										fecharMensagem();
										configBotao.onPress?.(temCheckbox ? valor : undefined);
										if (!opcoes.obrigatoria) setOpcoes(null);
									}}
									sx={{ ml: 1 }}
								>
									{botao.label}
								</Button>
							);
						})}
					</Box>
				)}
			</Box>
		</Modal >
	);
});

PopUp.displayName = 'PopUp';