import { Pagination, Paper, TableFooter, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, IconButton, Icon, Box, Stack, CircularProgress, Typography, Grid, Checkbox, FormControlLabel, Card, CardContent, FormControl, FormGroup, Button, FormLabel, InputLabel } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from 'shared/components';
import { useDebounce1, useDebounce2, useDebounce3, useDebounce4 } from 'shared/hooks';
import { LayoutBaseDePagina } from 'shared/layouts';
import { IDetalheDocumentosAvulsosDetalhado, DocumentosAvulsosDetalhadoService } from 'shared/services/DocumentosAvulsosDetalhado/DocumentosAvulsosDetalhadoService';
import { IListagemTipologia, IListagemTipologiaCampos, TipologiaService } from 'shared/services/Tipologias/TipologiasService';
import { NumberFormatBase } from 'react-number-format';

import './mapLinks.css';

export const ListagemDeDocumentosAvulsosDetalhado: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [totalCount, setTotalCount] = useState(0);
	const navigate = useNavigate();

	const [isLoadingTipologias, setIsLoadingTipologias] = useState(true);
	const [isLoadingTipologia, setIsLoadingTipologia] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [rowsTipologia, setRowsTipologia] = useState<IListagemTipologia[]>([]);
	const [rowsTipologiaCampos, setRowsTipologiaCampos] = useState<IListagemTipologiaCampos[]>([]);
	const [rowsFiles, setRowsFiles] = useState<IDetalheDocumentosAvulsosDetalhado[]>([]);
	const { debounce1 } = useDebounce1(50, false);
	const { debounce2 } = useDebounce2(50, false);
	const { debounce3 } = useDebounce3(50, false);
	const { debounce4 } = useDebounce4(50, false);
	const [tipologiaId, settipologiaId] = useState('1');
	const { id = '' } = useParams<'id'>();
	const [filtrarVermelho, setFiltrarVermelho] = useState(false);
	const [tipologianome, SetTipologiaNome] = useState('0');

	const busca = useMemo(() => {
		return searchParams.get('busca') || '';
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get('pagina') || '1');
	}, [searchParams]);

	useEffect(() => {
		/* ************************************************************************** */
		debounce1(() => {
			if (isLoadingTipologia) {
				TipologiaService.getById(Number(tipologiaId))
					.then((result) => {
						setIsLoadingTipologia(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							SetTipologiaNome(result?.nome);
						}
					});
			}
		});
		debounce2(() => {
			if (isLoadingTipologias) {
				TipologiaService.getAll()
					.then((result) => {
						setIsLoadingTipologias(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							setRowsTipologia(result.data);
						}
					});
			}
		});
		debounce3(() => {
			setIsLoading(true);
			DocumentosAvulsosDetalhadoService.getAll(pagina, busca, tipologiaId, id)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setRowsFiles(result.data);
						busca.length > 0 ? setFiltrarVermelho(true) : null;
					}
				});
			DocumentosAvulsosDetalhadoService.getTotal(busca, tipologiaId, id)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setTotalCount(result.totalCount);
					}
				});
		});
		debounce4(() => {
			if (isLoadingTipologia) {
				TipologiaService.getFields(tipologiaId, id)
					.then((tipologiasCampos) => {
						if (tipologiasCampos instanceof Error) {
							alert(tipologiasCampos.message);
							return;
						} else {
							setRowsTipologiaCampos(tipologiasCampos);
						}
					});
			}
		});
	}, [busca, pagina, tipologiaId, id]);

	const geralist = (t: string) => {
		if (t == tipologiaId) return;
		settipologiaId(t);
		setIsLoadingTipologia(true);
		setSearchParams({ busca: busca, pagina: '1', tipologiaId: t }, { replace: true }); // Gera lista string de marcados
	};

	useEffect(() => {
		const tipologiaId = searchParams.get('tipologiaId');
		if (tipologiaId) {
			settipologiaId(tipologiaId);
			setIsLoadingTipologia(true);
		}
	}, [searchParams]);


	let listcampos = Array(''); // Desmarcar todos
	// Monta o array com os ids dos registros do banco
	listcampos = (rowsTipologiaCampos.map((x) => { return (x.fieldName.replace(/(string)/g, 'String').replace(/(double)/g, 'Double').replace(/(date)/g, 'Date')); }));

	return (
		<LayoutBaseDePagina>
			<Stack direction='row'>
				<TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: '25%' }}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<Box margin={1} display='flex' flexDirection='column' component={Paper} variant='outlined' sx={{ m: 0, width: '100%' }}>
										<Card>
											<CardContent>
												<FormControl component='fieldset'>
													<FormGroup aria-label='position'>
														<Box justifyContent='end' height={70} >
															<Button
																color='info'
																disableElevation
																variant='contained'
																onClick={() => { alert('Cadastro de novo processo em breve!'); }}
																startIcon={<Icon>add</Icon>} >
																<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
																	Processo
																</Typography>
															</Button>
														</Box>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem' }}>
															<Typography variant='button' whiteSpace='nowrap'
																textOverflow='ellipsis' overflow='hidden' fontSize={14} >
																Tipologias
															</Typography>
														</Grid>
														{isLoadingTipologias && (
															<CircularProgress />
														)}
														{!isLoadingTipologias && rowsTipologia.map(row => {
															return (
																<Grid item key={row.tipologiaID} xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem' }}>
																	<Box sx={{ '&:hover': { cursor: 'pointer' } }}
																		onClick={() => geralist(row.tipologiaID.toString())} >
																		<Typography variant='caption' whiteSpace='nowrap'
																			textOverflow='ellipsis' overflow='hidden' fontSize={14} fontWeight={tipologiaId == row.tipologiaID.toString() ? 'bold' : ''}>
																			{row.nome}
																		</Typography>
																	</Box>
																</Grid>
															);
														})}
													</FormGroup>
												</FormControl>
											</CardContent>
										</Card>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: '75%' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={20} align='right'>
									<div className='mapFolder'>
										<div className='mapMain'>
											<div className='mapLink' onClick={() => navigate('/pesquisar')}>Home</div>
											<div className='mapLink' onClick={() => navigate('/listardocumentosprocessos')}>&nbsp;/ Processos</div>&nbsp;
										</div>
									</div>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={20} align='left'>
									<Box display='flex' p={1} width={300}>
										{!isLoadingTipologia && tipologianome != '' && (
											<Box p={1} flexGrow={1}>
												<Card variant='outlined' sx={{
													boxShadow: 0,
													borderRadius: 0,
													border: 0,
													p: 0,
													minWidth: 150,
													maxWidth: 200,
												}} >
													<Typography key={0} fontSize={18} align='center' color='#085784' fontWeight=''>
														{tipologianome}
													</Typography>
												</Card>
											</Box>
										)}
									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={20} align='center'>
									<FerramentasDaListagem
										mostrarInputBuscaIndexado={true}
										mostrarBotaoVoltar={false}
										/* aoClicarEmVoltar={() => navigate('/Dashboard')} */
										mostrarBotaoNovo={true}
										textoBotaoNovo='Novo'
										textoDaBuscaIndexado={searchParams.get('busca') ?? ''}
										tipologiaBuscaIndexado={tipologiaId}
										aoClicarEmNovo={() => navigate('/pesquisar')}
										aoMudarTextoDeBuscaIndexado={texto => { setSearchParams({ busca: texto, pagina: '1', tipologiaId: tipologiaId }, { replace: true }); }}
									/>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading && (
								<CircularProgress />
							)}
							<TableRow>
								{!isLoading && rowsTipologiaCampos.map((rowTitulos, key) => {
									return (
										<TableCell key={key}>
											<Typography fontSize={14} color='#085784' fontWeight='bold'>
												{rowTitulos.titulo}
											</Typography>
										</TableCell>
									);
								})}
							</TableRow>
							{!isLoading && rowsFiles.map(rowFiles => {
								return (
									<TableRow key={rowFiles.documentoCamposID}>
										{listcampos.map((cmp, index) => {
											let conteudo;
											conteudo = (cmp == 'field01String' ? rowFiles.field01String : conteudo);
											conteudo = (cmp == 'field02String' ? rowFiles.field02String : conteudo);
											conteudo = (cmp == 'field03String' ? rowFiles.field03String : conteudo);
											conteudo = (cmp == 'field04String' ? rowFiles.field04String : conteudo);
											conteudo = (cmp == 'field05String' ? rowFiles.field05String : conteudo);
											conteudo = (cmp == 'field06String' ? rowFiles.field06String : conteudo);
											conteudo = (cmp == 'field07String' ? rowFiles.field07String : conteudo);
											conteudo = (cmp == 'field08String' ? rowFiles.field08String : conteudo);
											conteudo = (cmp == 'field09String' ? rowFiles.field09String : conteudo);
											conteudo = (cmp == 'field10String' ? rowFiles.field10String : conteudo);
											conteudo = (cmp == 'field11String' ? rowFiles.field11String : conteudo);
											conteudo = (cmp == 'field12String' ? rowFiles.field12String : conteudo);
											conteudo = (cmp == 'field13String' ? rowFiles.field13String : conteudo);
											conteudo = (cmp == 'field14String' ? rowFiles.field14String : conteudo);
											conteudo = (cmp == 'field15String' ? rowFiles.field15String : conteudo);
											conteudo = (cmp == 'field16String' ? rowFiles.field16String : conteudo);
											conteudo = (cmp == 'field17String' ? rowFiles.field17String : conteudo);
											conteudo = (cmp == 'field18String' ? rowFiles.field18String : conteudo);
											conteudo = (cmp == 'field19String' ? rowFiles.field19String : conteudo);
											conteudo = (cmp == 'field20String' ? rowFiles.field20String : conteudo);
											conteudo = (cmp == 'field21String' ? rowFiles.field21String : conteudo);
											conteudo = (cmp == 'field22String' ? rowFiles.field22String : conteudo);
											conteudo = (cmp == 'field23String' ? rowFiles.field23String : conteudo);
											conteudo = (cmp == 'field24String' ? rowFiles.field24String : conteudo);
											conteudo = (cmp == 'field25String' ? rowFiles.field25String : conteudo);
											conteudo = (cmp == 'field26String' ? rowFiles.field26String : conteudo);
											conteudo = (cmp == 'field27String' ? rowFiles.field27String : conteudo);
											conteudo = (cmp == 'field28String' ? rowFiles.field28String : conteudo);
											conteudo = (cmp == 'field29String' ? rowFiles.field29String : conteudo);
											conteudo = (cmp == 'field30String' ? rowFiles.field30String : conteudo);
											conteudo = (cmp == 'field31String' ? rowFiles.field31String : conteudo);
											conteudo = (cmp == 'field32String' ? rowFiles.field32String : conteudo);
											conteudo = (cmp == 'field33String' ? rowFiles.field33String : conteudo);
											conteudo = (cmp == 'field34String' ? rowFiles.field34String : conteudo);
											conteudo = (cmp == 'field35String' ? rowFiles.field35String : conteudo);
											conteudo = (cmp == 'field36String' ? rowFiles.field36String : conteudo);
											conteudo = (cmp == 'field37String' ? rowFiles.field37String : conteudo);
											conteudo = (cmp == 'field38String' ? rowFiles.field38String : conteudo);
											conteudo = (cmp == 'field39String' ? rowFiles.field39String : conteudo);
											conteudo = (cmp == 'field40String' ? rowFiles.field40String : conteudo);
											conteudo = (cmp == 'field41String' ? rowFiles.field41String : conteudo);
											conteudo = (cmp == 'field42String' ? rowFiles.field42String : conteudo);
											conteudo = (cmp == 'field43String' ? rowFiles.field43String : conteudo);
											conteudo = (cmp == 'field44String' ? rowFiles.field44String : conteudo);
											conteudo = (cmp == 'field45String' ? rowFiles.field45String : conteudo);
											conteudo = (cmp == 'field46String' ? rowFiles.field46String : conteudo);
											conteudo = (cmp == 'field47String' ? rowFiles.field47String : conteudo);
											conteudo = (cmp == 'field48String' ? rowFiles.field48String : conteudo);
											conteudo = (cmp == 'field49String' ? rowFiles.field49String : conteudo);
											conteudo = (cmp == 'field50String' ? rowFiles.field50String : conteudo);
											conteudo = (cmp == 'field51String' ? rowFiles.field51String : conteudo);
											conteudo = (cmp == 'field52String' ? rowFiles.field52String : conteudo);
											conteudo = (cmp == 'field53String' ? rowFiles.field53String : conteudo);
											conteudo = (cmp == 'field54String' ? rowFiles.field54String : conteudo);
											conteudo = (cmp == 'field55String' ? rowFiles.field55String : conteudo);
											conteudo = (cmp == 'field56String' ? rowFiles.field56String : conteudo);
											conteudo = (cmp == 'field57String' ? rowFiles.field57String : conteudo);
											conteudo = (cmp == 'field58String' ? rowFiles.field58String : conteudo);
											conteudo = (cmp == 'field59String' ? rowFiles.field59String : conteudo);
											conteudo = (cmp == 'field60String' ? rowFiles.field60String : conteudo);
											conteudo = (cmp == 'field61Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field61Double))) : conteudo);
											conteudo = (cmp == 'field62Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field62Double))) : conteudo);
											conteudo = (cmp == 'field63Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field63Double))) : conteudo);
											conteudo = (cmp == 'field64Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field64Double))) : conteudo);
											conteudo = (cmp == 'field65Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field65Double))) : conteudo);
											conteudo = (cmp == 'field66Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field66Double))) : conteudo);
											conteudo = (cmp == 'field67Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field67Double))) : conteudo);
											conteudo = (cmp == 'field68Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field68Double))) : conteudo);
											conteudo = (cmp == 'field69Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field69Double))) : conteudo);
											conteudo = (cmp == 'field70Double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field70Double))) : conteudo);
											conteudo = (cmp == 'field71Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field71Date)))) : conteudo);
											conteudo = (cmp == 'field72Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field72Date)))) : conteudo);
											conteudo = (cmp == 'field73Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field73Date)))) : conteudo);
											conteudo = (cmp == 'field74Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field74Date)))) : conteudo);
											conteudo = (cmp == 'field75Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field75Date)))) : conteudo);
											conteudo = (cmp == 'field76Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field76Date)))) : conteudo);
											conteudo = (cmp == 'field77Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field77Date)))) : conteudo);
											conteudo = (cmp == 'field78Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field78Date)))) : conteudo);
											conteudo = (cmp == 'field79Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field79Date)))) : conteudo);
											conteudo = (cmp == 'field80Date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field80Date)))) : conteudo);
											return (<TableCell key={rowFiles.documentoCamposID + index}>{conteudo}</TableCell>);
										})}
									</TableRow>
								);
							})}
						</TableBody>
						<TableFooter>
							{totalCount === 0 && !isLoading && (
								<TableRow>
									<TableCell colSpan={2}>
										{process.env.REACT_APP_LISTAGEM_VAZIA}
									</TableCell>
								</TableRow>
							)}
							{totalCount > 0 && totalCount > parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!) && (
								<TableRow>
									<TableCell colSpan={2}>
										<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
											<Pagination
												page={pagina}
												count={Math.ceil(totalCount / parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!))}
												onChange={(_, newPage) => setSearchParams({ busca: busca, pagina: newPage.toString(), tipologiaId: tipologiaId }, { replace: true })}
											/>
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table >
				</TableContainer >
			</Stack >
			<Grid container xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem', justifyContent: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};