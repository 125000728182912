import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

interface ICricularProgressCustom {
	loadding: boolean;
	children: React.ReactNode;
}

export const CircularProgressCustom: FC<ICricularProgressCustom> = ({ loadding, children }) => {
	if (!loadding)
		return children;
	return (
		<Box p={3} display='flex' justifyContent='center'>
			<CircularProgress />
		</Box>
	);
};