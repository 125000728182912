import { EUnidadeTempo } from 'enums';

export class SetorRequest {
	setorID: number;
	nome: string;
	empresaID: number;

	constructor(dto?: any) {
		this.setorID = dto?.setorID;
		this.nome = dto?.nome;
		this.empresaID = dto?.empresaID;
	}
}