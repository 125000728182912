import React, { createContext, useRef, useContext, FC } from 'react';
import { IBotoesPopUp, ICheckboxPopUp, IPopUp, PopUp } from 'shared/components';

enum ETipoMensagem {
	ATENCAO = 0,
	SUCESSO = 1,
	ERRO = 2,
	INFO = 3,
	PADRAO = 4,
	AGUARDE = 5
}

interface IMensagemContext {
	Atencao: (titulo: string | null, conteudo: string | (() => JSX.Element) | IConteudo | null, botoesOuTempo: IBotoesPopUp[] | number, funcaoFinal?: (() => void) | null) => void;
	Sucesso: (titulo: string | null, conteudo: string | (() => JSX.Element) | IConteudo | null, botoesOuTempo: IBotoesPopUp[] | number, funcaoFinal?: (() => void) | null) => void;
	Erro: (titulo: string | null, conteudo: string | (() => JSX.Element) | IConteudo | null, botoesOuTempo: IBotoesPopUp[] | number, funcaoFinal?: (() => void) | null) => void;
	Informacao: (titulo: string | null, conteudo: string | (() => JSX.Element) | IConteudo | null, botoesOuTempo: IBotoesPopUp[] | number, funcaoFinal?: (() => void) | null) => void;
	Padrao: (titulo: string | null, conteudo: string | (() => JSX.Element) | IConteudo | null, botoesOuTempo: IBotoesPopUp[] | number, funcaoFinal?: (() => void) | null) => void;
	FecharMensagem: () => void;
}

interface IConteudo {
	mensagem: string;
	obrigatoria: boolean;
	checkbox: ICheckboxPopUp | null;
}

const MensagemContexto = createContext<IMensagemContext>({} as IMensagemContext);

const MensagemProvider: FC<any> = ({ children }) => {
	const mensagemRef = useRef<IPopUp | null>(null);

	const Mensagem = (tipo: ETipoMensagem, titulo: string | null = null, conteudo: string | (() => JSX.Element) | IConteudo | null = null, botoesOuTempo: any[] | number = 1000, funcaoFinal?: (() => void) | null) => {

		const ehTexto = conteudo && typeof conteudo === 'string';
		const ehObjeto = conteudo && typeof conteudo === 'object';
		const ehComponente = conteudo && typeof conteudo === 'function';
		const ehBotoes = botoesOuTempo instanceof Array;

		mensagemRef?.current?.abrirMensagem({
			tipo,
			titulo,
			mensagem: ehTexto ? conteudo : (ehObjeto ? conteudo.mensagem : null),
			obrigatoria: ehTexto ? conteudo != '' : (ehObjeto ? conteudo.obrigatoria : true),
			checkbox: ehObjeto && !!conteudo.checkbox ? conteudo.checkbox : null,
			componente: ehComponente ? conteudo : null,
			botoes: ehBotoes ? botoesOuTempo : [],
			tempo: !ehBotoes ? (botoesOuTempo ? botoesOuTempo : 2000) : null,
			funcaoFinal: funcaoFinal
		});
	};

	const Atencao = (titulo: string | null = null, conteudo: string | (() => JSX.Element) | IConteudo | null = null, botoesOuTempo: any[] | number = 1000, funcaoFinal?: (() => void) | null) =>
		Mensagem(ETipoMensagem.ATENCAO, titulo, conteudo, botoesOuTempo, funcaoFinal);

	const Sucesso = (titulo: string | null = null, conteudo: string | (() => JSX.Element) | IConteudo | null = null, botoesOuTempo: any[] | number = 1000, funcaoFinal?: (() => void) | null) =>
		Mensagem(ETipoMensagem.SUCESSO, titulo, conteudo, botoesOuTempo, funcaoFinal);

	const Erro = (titulo: string | null = null, conteudo: string | (() => JSX.Element) | IConteudo | null = null, botoesOuTempo: any[] | number = 1000, funcaoFinal?: (() => void) | null) =>
		Mensagem(ETipoMensagem.ERRO, titulo, conteudo, botoesOuTempo, funcaoFinal);

	const Informacao = (titulo: string | null = null, conteudo: string | (() => JSX.Element) | IConteudo | null = null, botoesOuTempo: any[] | number = 1000, funcaoFinal?: (() => void) | null) =>
		Mensagem(ETipoMensagem.INFO, titulo, conteudo, botoesOuTempo, funcaoFinal);

	const Padrao = (titulo: string | null = null, conteudo: string | (() => JSX.Element) | IConteudo | null = null, botoesOuTempo: any[] | number = 1000, funcaoFinal?: (() => void) | null) =>
		Mensagem(ETipoMensagem.PADRAO, titulo, conteudo, botoesOuTempo, funcaoFinal);

	const FecharMensagem = () => mensagemRef.current && mensagemRef.current.fecharMensagem();

	return (
		<MensagemContexto.Provider value={{
			Atencao,
			Sucesso,
			Erro,
			Informacao,
			Padrao,
			FecharMensagem
		}}>
			<PopUp ref={mensagemRef} />
			{children}
		</MensagemContexto.Provider>
	);
};

const useMensagem = (): IMensagemContext => useContext<IMensagemContext>(MensagemContexto);

export { MensagemContexto, MensagemProvider, useMensagem };