import { ESituacao, EUnidadeTempo } from 'enums';

export class TipologiaEmpresaRequest {
	tipologiaID: number;
	unidadeTempoID: EUnidadeTempo;
	tempo: number;
	tipologiaEmpresaID: number;
	situacaoID: ESituacao;

	constructor(dto?: any) {
		this.tipologiaEmpresaID = dto?.tipologiaEmpresaID;
		this.tipologiaID = dto?.tipologiaID;
		this.unidadeTempoID = dto?.unidadeTempoID;
		this.tempo = dto?.tempo;
		this.situacaoID = dto?.situacaoID;
	}
}