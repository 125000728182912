import { EUFIBGE } from 'enums';
import { plainToClass } from 'class-transformer';

export class UF {
	ufID!: number;
	nome!: string;
	sigla!: string;

	constructor(dto: any) {
		this.ufID = dto?.ufId;
		this.nome = dto?.nome;
		this.sigla = dto?.sigla;
	}

	static create(uf: any): UF {
		return plainToClass(UF, {
			ufID: uf?.UFID,
			nome: uf?.Nome,
			sigla: uf?.Sigla
		});
	}

	static ufId(Sigla: string): number {
		return EUFIBGE[Sigla as keyof typeof EUFIBGE];
	}

	static UFs(): Array<UF> {
		return Object.entries(EUFIBGE).map(([sigla, ufId]) => new UF({ sigla, ufId }));
	}
}