import { ObjectToQuery } from 'utils';
import { Api } from '../Axios';

export interface IDetalheDocumentosAvulsosDetalhado {
	documentoCamposID: number;
	tipologiaID: number;
	processoID: number;
	field01String: string;
	field02String: string;
	field03String: string;
	field04String: string;
	field05String: string;
	field06String: string;
	field07String: string;
	field08String: string;
	field09String: string;
	field10String: string;
	field11String: string;
	field12String: string;
	field13String: string;
	field14String: string;
	field15String: string;
	field16String: string;
	field17String: string;
	field18String: string;
	field19String: string;
	field20String: string;
	field21String: string;
	field22String: string;
	field23String: string;
	field24String: string;
	field25String: string;
	field26String: string;
	field27String: string;
	field28String: string;
	field29String: string;
	field30String: string;
	field31String: string;
	field32String: string;
	field33String: string;
	field34String: string;
	field35String: string;
	field36String: string;
	field37String: string;
	field38String: string;
	field39String: string;
	field40String: string;
	field41String: string;
	field42String: string;
	field43String: string;
	field44String: string;
	field45String: string;
	field46String: string;
	field47String: string;
	field48String: string;
	field49String: string;
	field50String: string;
	field51String: string;
	field52String: string;
	field53String: string;
	field54String: string;
	field55String: string;
	field56String: string;
	field57String: string;
	field58String: string;
	field59String: string;
	field60String: string;
	field61Double: number;
	field62Double: number;
	field63Double: number;
	field64Double: number;
	field65Double: number;
	field66Double: number;
	field67Double: number;
	field68Double: number;
	field69Double: number;
	field70Double: number;
	field71Date: string;
	field72Date: string;
	field73Date: string;
	field74Date: string;
	field75Date: string;
	field76Date: string;
	field77Date: string;
	field78Date: string;
	field79Date: string;
	field80Date: string;
}

type TDocumentosComTotalCount = {
	data: IDetalheDocumentosAvulsosDetalhado[];
	totalCount: number;
}

type TDocumentosSommenteTotalCount = {
	totalCount: number;
}

const getAll = async (pagina = 1, documentoNome = '', tipologiaId = '', processoId = ''): Promise<TDocumentosComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const params = ObjectToQuery({ pagina, documentoNome, tipologiaId, processoId });
		const urlRelativa = `/DocumentoCampos/ObterDocumentosAvulsosDetalhados?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getTotal = async (documentoNome = '', tipologiaId = '', processoId = ''): Promise<TDocumentosSommenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const params = ObjectToQuery({ documentoNome, tipologiaId, processoId });
		const urlRelativa = `/DocumentoCampos/ObterQtdeTotal?${params}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

export const DocumentosAvulsosDetalhadoService = {
	getAll,
	getTotal
};