import { EUnidadeTempo } from 'enums';

export class TipologiaRequest {
	tipologiaID: number;
	nome: string;
	empresaID: number;
	baseLegal: string;
	descricao: string;
	semDescricao: boolean;
	unidadeTempoID: EUnidadeTempo;
	tempo: number;

	constructor(dto?: any) {
		this.tipologiaID = dto?.tipologiaID;
		this.nome = dto?.nome;
		this.empresaID = dto?.empresaID;
		this.baseLegal = dto?.baseLegal;
		this.descricao = dto?.descricao;
		this.semDescricao = dto?.semDescricao;
		this.unidadeTempoID = dto?.unidadeTempoID;
		this.tempo = dto?.tempo;
	}
}