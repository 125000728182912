import { FC } from 'react';
import { Grid2 as Grid, Grid2Props, styled } from '@mui/material';

export const LineGrid: FC<Grid2Props> = ({
	children,
	size = 12,
	display = 'flex',
	flexDirection = 'row',
	spacing = 3,
	...props
}) => <Grid
	{...props}
	size={size}
	display={display}
	flexDirection={flexDirection}
	spacing={spacing}>
		{children}
	</Grid>;


export const FormGrid = styled(Grid)(() => ({
	display: 'flex',
	flexDirection: 'column',
}));
