import { Empresa } from './Empresa';

export class Usuario {
	usuarioID?: number;
	nome?: string;
	empresas?: Empresa[];
	email: string;
	senha: string;

	constructor(dto: any) {
		this.usuarioID = dto?.usuarioID;
		this.nome = dto?.nome;
		this.email = dto?.senha;
		this.senha = dto?.email;
		this.empresas = dto?.empresas?.map((empresa: any) => new Empresa(empresa));
	}
}