import { Tipologia } from 'models';
import { ObjectToQuery } from 'utils';
import { Api } from '../Axios';
import { EOrdenacao } from 'enums';
import { TipologiaRequest } from 'requests';

export interface IListagemTipologia {
	tipologiaID: number;
	nome: string;
}

type TTipologiasComTotalCount = {
	data: Tipologia[];
	totalCount: number;
}

type TTipologiasSomenteTotalCount = {
	totalCount: number;
}

export interface IListagemTipologiaCampos {
	tipologiaID: string;
	fieldName: string;
	ordem: string;
	tipoID: string;
	titulo: string;
}

type TTipologiasComTotal = {
	tipologias: Tipologia[];
	total: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TTipologiasSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/Tipologia/ObterQtdeTotal';
		// const urlRelativa = `/tipologias/getTotal.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&tipologia_nome_like=${filter}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (empresaID?: number): Promise<TTipologiasComTotalCount | Error> => {
	try {
		const params = ObjectToQuery({ empresaID });
		const urlRelativa = `/Tipologia/ObterTodos?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAllFiels = async (): Promise<IListagemTipologiaCampos[] | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/TipologiaCampos/ObterTodos';
		// const urlRelativa = `/tipologias/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&tipologia_nome_like=${filter}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data)
			return data.data;

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getFields = async (tipologiaId = '', processoId = ''): Promise<IListagemTipologiaCampos[] | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = `/TipologiaCampos/ObterCamposPorTipologiaId?tipologiaId=${tipologiaId}&processoId=${processoId}`;
		// const urlRelativa = `/tipologias/getFields.php?accessToken=${accessToken}&tipologiaId=${tipologiaId}&processoId=${processoId}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getWithPagination = async (pagina: number = 1, qtdLinha: number = 10, campoOrdenacao: string, ordenacaoPor: EOrdenacao = EOrdenacao.ASC, filtros: any = {}): Promise<TTipologiasComTotal | Error> => {
	try {
		const queryParams = ObjectToQuery({
			pagina: pagina + 1,
			qtdLinha,
			ordenacaoPor,
			campoOrdenacao,
			...filtros
		});
		const urlRelativa = `/Tipologia/ObterPaginado?${queryParams}`;
		const { data } = await Api.get(urlRelativa);

		if (data && data.data)
			return {
				tipologias: data.data.lista?.map((tipologia: any) => new Tipologia(tipologia)),
				total: data.data.total
			};

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<Tipologia | Error> => {
	try {
		const { data } = await Api.get(`/Tipologia/ObterPorId?tipologiaID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const getByEnterpriseId = async (id: number): Promise<Tipologia[] | Error> => {
	try {
		const { data } = await Api.get(`/Tipologia/ObterPorId?empresaID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const save = async (tipologia: TipologiaRequest): Promise<Tipologia | Error> => {
	try {
		const urlRelativa = `/Tipologia`;
		const { data } = await Api.post(urlRelativa, tipologia);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao cadastrar tipologia.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const update = async (tipologia: TipologiaRequest): Promise<Tipologia | Error> => {
	try {
		const urlRelativa = `/Tipologia/Alterar`;
		const { data } = await Api.post(urlRelativa, tipologia);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao alterar tipologia.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const inative = async (tipologiaID: number): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Tipologia/Inativar`;
		const { data } = await Api.post(urlRelativa, { tipologiaID });

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao inativar tipologia.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const addLink = async (tipologiaID: number, empresaID: number): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Tipologia/AdicionarVinculo`;
		const { data } = await Api.post(urlRelativa, { tipologiaID, empresaID });

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao adicionar vinculo à tipologia.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const removeLink = async (tipologiaID: number, empresaID: number): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Tipologia/RemoverVinculo`;
		const { data } = await Api.post(urlRelativa, { tipologiaID, empresaID });

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao remover vinculo à tipologia.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

export const TipologiaService = {
	getTotal,
	getAll,
	getFields,
	getById,
	getByEnterpriseId,
	getAllFiels,
	getWithPagination,
	save,
	update,
	inative,
	addLink,
	removeLink
};