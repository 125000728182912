import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	Stack,
	TableHead,
	Icon,
	Button,
	TablePagination,
	TableSortLabel,
	ThemeProvider,
	IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { Tipologia } from 'models';
import { EOrdenacao, ESituacao } from 'enums';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { TipologiaService } from 'shared/services/Tipologias/TipologiasService';
import { visuallyHidden } from '@mui/utils';
import { createTheme, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';

import { useAutenticacaoContext, useEmpresaSelecionadaContext, useEspera, useMensagem } from 'contexts';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { AlterButtonWithIcon, DefaultButtonWithIcon, DeleteButtonWithIcon, ViewButtonWithIcon } from 'shared/components/buttons';

interface ITipologiaTable {
	tipologiaID: number;
	nome: string;
	usuarioNomeCadastro: string;
	vinculado: string;
	situacao: string;
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof ITipologiaTable;
	label: string;
	left: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'tipologiaID',
		left: true,
		disablePadding: true,
		label: 'ID',
	},
	{
		id: 'nome',
		left: true,
		disablePadding: false,
		label: 'Nome',
	},
	{
		id: 'usuarioNomeCadastro',
		left: false,
		disablePadding: false,
		label: 'Usuário Cadastro',
	},
	{
		id: 'vinculado',
		left: false,
		disablePadding: false,
		label: 'Vinculado',
	},
	{
		id: 'situacao',
		left: false,
		disablePadding: false,
		label: 'Situação',
	},
];

interface EnhancedTableProps {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ITipologiaTable) => void;
	order: EOrdenacao;
	orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler =
		(property: keyof ITipologiaTable) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {

					const orderDirection = EOrdenacao[order].toLowerCase() as 'asc' | 'desc';
					const sortDirection = orderBy === headCell.id ? orderDirection : false;
					const direction = orderBy === headCell.id ? orderDirection : 'asc';
					const iconName = order == EOrdenacao.DESC ? 'sorted descending' : 'sorted ascending';

					return (
						<TableCell
							key={headCell.id}
							align={headCell.left ? 'left' : 'center'}
							padding='normal'
							sortDirection={sortDirection}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={direction}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{iconName}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					);
				})}

				<TableCell
					key={9999}
					align='center'
					padding='normal'
				>
					&nbsp;
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export const Tipologias: React.FC = () => {
	const { debounce } = useDebounce(300, false);
	const [isLoadingTipologias, setIsLoadingTipologias] = useState(true);
	const [rowsTipologias, setRowsTipologias] = useState<ITipologiaTable[]>([]);
	const navigate = useNavigate();

	const [ordenarPor, setOrdenarPor] = useState<EOrdenacao>(EOrdenacao.ASC);
	const [campoOrdenacao, setCampoOrdenacao] = useState<keyof ITipologiaTable>('tipologiaID');
	const [pagina, setPagina] = useState(0);
	const [total, setTotal] = useState(0);
	const [qtdPagina, setQtdPagina] = useState(parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!));
	const theme = useTheme();
	const { Informacao } = useMensagem();
	const { BloquearTela, DesbloquearTela } = useEspera();
	const { empresaSelecionada } = useEmpresaSelecionadaContext();


	const handleRequestSort = (
		event: MouseEvent<unknown>,
		property: keyof ITipologiaTable,
	) => {
		const isAsc = campoOrdenacao === property && ordenarPor === EOrdenacao.ASC;
		setOrdenarPor(isAsc ? EOrdenacao.DESC : EOrdenacao.ASC);
		setCampoOrdenacao(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPagina(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQtdPagina(parseInt(event.target.value));
		setPagina(0);
	};

	const emptyRows =
		pagina > 0 ? Math.max(0, (1 + pagina) * (qtdPagina - rowsTipologias.length)) : 0;

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales['ptBR']),
		[theme],
	);

	const getTipologias = useCallback(() => {
		BloquearTela();
		from(TipologiaService.getWithPagination(pagina, qtdPagina, campoOrdenacao, ordenarPor, { empresaID: empresaSelecionada?.empresaID }))
			.pipe(
				tap(() => setIsLoadingTipologias(false)),
				switchMap((retorno: any | Error) => {
					if (retorno instanceof Error)
						return throwError(() => retorno);

					const { tipologias, total } = retorno;

					return of({
						Tipologias: tipologias
							.map(({
								tipologiaID,
								nome,
								usuarioNomeCadastro,
								vinculado,
								situacaoID
							}: Tipologia) => ({
								tipologiaID,
								nome,
								usuarioNomeCadastro,
								vinculado: vinculado ? 'Sim' : 'Não',
								situacao: ESituacao[situacaoID]
							} as ITipologiaTable)),
						total
					});
				}),
				tap(() => DesbloquearTela()),
			)
			.subscribe({
				next: ({ Tipologias, total }: any) => {
					setRowsTipologias(Tipologias);
					setTotal(total);
				},
				error: (error) => console.log(error)
			});
	}, [pagina, qtdPagina, campoOrdenacao, ordenarPor]);

	const InativarTipologia = useCallback((tipologiaID: number, nomeFantasia: string) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja inativar a Tipologia <Typography fontWeight={700}>&nbsp;{nomeFantasia}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						BloquearTela();
						from(TipologiaService.inative(tipologiaID))
							.pipe(
								switchMap((retorno: boolean | Error) => {
									if (retorno instanceof Error)
										return throwError(() => retorno.message);
									return of(retorno);
								}),
								tap(() => {
									getTipologias();
									DesbloquearTela();
								})
							)
							.subscribe({
								next: () => ToastSucesso(`Tipologia inativada com sucesso`),
								error: (erro) => ToastErro(erro),
							});
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	}, []);

	const AdicionarVinculoTipologia = useCallback((tipologiaID: number) => {
		Informacao(
			'Atenção',
			() =>
				<Box display='flex' flexDirection='row' whiteSpace='wrap'>
					Deseja adicionar a Tipologia <Typography fontWeight={700}>&nbsp;{empresaSelecionada?.nomeFantasia}&nbsp;</Typography> para ser usada em sua empresa?
				</Box>,
			[
				{
					label: 'Sim',
					onPress: () => {
						BloquearTela();
						from(TipologiaService.addLink(tipologiaID, empresaSelecionada?.empresaID || 0))
							.pipe(
								switchMap((retorno: boolean | Error) => {
									if (retorno instanceof Error)
										return throwError(() => retorno.message);
									return of(retorno);
								}),
								tap(() => {
									getTipologias();
									DesbloquearTela();
								})
							)
							.subscribe({
								next: () => ToastSucesso(`Tipologia vinculada com sucesso`),
								error: (erro) => ToastErro(erro),
							});
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	}, []);

	const RemoverVinculoTipologia = useCallback((tipologiaID: number) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row'>
					Deseja adicionar a Tipologia <Typography fontWeight={700}>&nbsp;{empresaSelecionada?.nomeFantasia}&nbsp;</Typography> para ser usada em sua empresa?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						BloquearTela();
						from(TipologiaService.removeLink(tipologiaID, empresaSelecionada?.empresaID || 0))
							.pipe(
								switchMap((retorno: boolean | Error) => {
									if (retorno instanceof Error)
										return throwError(() => retorno.message);
									return of(retorno);
								}),
								tap(() => {
									getTipologias();
									DesbloquearTela();
								})
							)
							.subscribe({
								next: () => ToastSucesso(`Tipologia vinculada com sucesso`),
								error: (erro) => ToastErro(erro),
							});
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	}, []);

	useEffect(() => {
		debounce(() => {
			getTipologias();
		});
	}, [pagina, qtdPagina, campoOrdenacao, ordenarPor]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						display='flex'
						flexDirection='row'
						size={12}
					>
						<Grid size={3} />
						<Grid
							size={8}
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='center'
						>
							<Typography variant='h4'>
								Tipologia
							</Typography>
						</Grid>
						<Grid
							size={3}
							container
							sx={{
								padding: '1rem',
								justifyContent: 'end',
							}}>
							<Button
								color='success'
								disableElevation
								variant='contained'
								onClick={() => navigate('/cadastrarTipologia')}
								startIcon={<Icon>add</Icon>}
								sx={{ marginLeft: 2 }}>
								<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
									Cadastrar
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid size={12}>
						<ThemeProvider theme={themeWithLocale}>
							<TableContainer>
								<Table
									sx={{ minWidth: 750 }}
									aria-labelledby='tableTitle'
									size='small'
								>
									<EnhancedTableHead
										order={ordenarPor}
										orderBy={campoOrdenacao}
										onRequestSort={handleRequestSort}
									/>
									<TableBody>
										{rowsTipologias.map(({
											tipologiaID,
											nome,
											usuarioNomeCadastro,
											vinculado,
											situacao
										}) => {
											return (
												<TableRow hover key={tipologiaID}>
													<TableCell align='left'>{tipologiaID}</TableCell>
													<TableCell align='left'>{nome}</TableCell>
													<TableCell align='center'>{usuarioNomeCadastro}</TableCell>
													<TableCell align='center'>{vinculado}</TableCell>
													<TableCell align='center'>{situacao}</TableCell>
													<TableCell align='center' width='15%'>
														<DeleteButtonWithIcon onClick={() => InativarTipologia(tipologiaID, nome)} />
														<AlterButtonWithIcon onClick={() => navigate('/alterarTipologia', { state: { tipologiaID } })} />
														<ViewButtonWithIcon onClick={() => navigate('/visualizarTipologia', { state: { tipologiaID } })} />
														<DefaultButtonWithIcon hide={vinculado != 'Sim'} icon='link_off' tooltip={{ title: 'Desvincular' }} onClick={() => RemoverVinculoTipologia(tipologiaID)} />
														<DefaultButtonWithIcon hide={vinculado == 'Sim'} icon='link' tooltip={{ title: 'Vincular' }} onClick={() => AdicionarVinculoTipologia(tipologiaID)} />
													</TableCell>
												</TableRow>
											);
										})}
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 33 * emptyRows,
												}}
											>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								component='div'
								count={total}
								rowsPerPage={qtdPagina}
								page={pagina}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</ThemeProvider>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'start' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate('/')}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};
