import React, { FC, useEffect, useMemo, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	TableHead,
	Icon,
	Button,
	TextField,
	styled,
	TextFieldVariants,
	Checkbox,
	FormControlLabel
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { DadosFormulario, ValidadorTipologia } from 'validators/Tipologia';
import { Validacao } from 'validators/Validador';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { useEspera, useMensagem } from 'contexts';
import { TipologiaService } from 'shared/services/Tipologias/TipologiasService';
import { TipologiaRequest } from 'requests/TipologiaRequest';

import { ToastErro, ToastSucesso } from 'utils/toast';
import { EUnidadeTempo } from 'enums';
import { EnumToArray } from 'utils';
import { FormGrid, LineGrid } from 'shared/components';

export const AlterarTipologia: React.FC = () => {
	const { state } = useLocation();
	const { tipologiaID } = state;

	const [isLoading, setIsLoading] = useState(true);
	const { debounce } = useDebounce(300, false);
	const navigate = useNavigate();

	const [formulario, setFormulario] = useState<DadosFormulario>({
		nome: '',
		descricao: '',
		semDescricao: false,
		baseLegal: '',
		tempo: '',
		unidadeTempo: ''
	});

	const [validacao, setValidacao] = useState<any>({
		nome: { valido: true, mensagem: null } as Validacao,
		descricao: { valido: true, mensagem: null } as Validacao,
		baseLegal: { valido: true, mensagem: null } as Validacao,
		tempo: { valido: true, mensagem: null } as Validacao,
		unidadeTempo: { valido: true, mensagem: null } as Validacao,
	});

	const { Padrao, Informacao } = useMensagem();
	const { BloquearTela, DesbloquearTela } = useEspera();

	const _set = (obj: any, validar: boolean = false) => {
		setFormulario((form: DadosFormulario) => ({ ...form, ...obj } as DadosFormulario));
	};

	const variant = useMemo<TextFieldVariants>(() => 'standard', []);
	const unidadesTempo = useMemo(() => EnumToArray(EUnidadeTempo).map(({ id, name }) => ({ unidadeTempoID: id, nome: name })), []);


	const handleNome = (event: { target: { value: string }; }) => _set({ nome: event.target.value });
	const handleBaseLegal = (event: { target: { value: string }; }) => _set({ baseLegal: event.target.value });
	const handleDescricao = (event: { target: { value: string }; }) => _set({ descricao: event.target.value?.substring(0, 10000) });
	const handleSemDescricao = (semDescricao: boolean) => _set({ semDescricao, descricao: '' });
	const handleUnidadeTempo = (event: { target: { value: string }; }) => _set({ unidadeTempo: event.target.value });
	const handleTempo = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value != '' && value != undefined)
			_set({ tempo: value });
	};

	const handleValidarCampo = (campo: keyof DadosFormulario) => {
		setValidacao((validados: any) => ({ ...validados, ...ValidadorTipologia.validarCampo(formulario[campo], campo) }));
	};

	const handleValidarValorCampo = (campo: keyof DadosFormulario, valor: any) => {
		setValidacao((validados: any) => ({ ...validados, ...ValidadorTipologia.validarCampo(valor, campo) }));
	};

	const CadastrarTipologia = () => {
		const camposValidados = ValidadorTipologia.validar(formulario);
		setValidacao(camposValidados);

		if (ValidadorTipologia.ehValido(camposValidados)) {

			Informacao(
				null,
				() => <Typography display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
					Deseja prosseguir com o cadastro da tipologia?
					<Typography fontWeight={600} fontSize={13} fontStyle='italic'>* Antes de confirmar, revise os dados informados.</Typography>
				</Typography>,
				[
					{
						label: 'Sim',
						onPress: () => {
							const alteracaoTipologia = new TipologiaRequest({
								tipologiaID: tipologiaID,
								nome: formulario.nome,
								baseLegal: formulario.baseLegal,
								descricao: formulario.descricao,
								semDescricao: formulario.semDescricao,
								unidadeTempoID: formulario.unidadeTempo,
								tempo: formulario.tempo,
							});

							BloquearTela();
							from(TipologiaService.update(alteracaoTipologia))
								.pipe(
									tap(() => DesbloquearTela()),
									switchMap((resposta) => {
										if (resposta instanceof Error)
											return throwError(() => resposta.message);
										return of(resposta);
									})
								)
								.subscribe({
									next: () => ToastSucesso(`Tipologia alterada com sucesso`),
									error: (erro) => ToastErro(erro),
									complete: () => navigate('/tipologia')
								});
						}
					},
					{
						label: 'Não',
						fechar: true
					}
				]
			);
		}
	};

	useEffect(() => {
		debounce(() => {
			setIsLoading(true);
			BloquearTela();
			from(TipologiaService.getById(tipologiaID))
				.pipe(
					tap(() => setIsLoading(false)),
					switchMap((resposta) => {
						setIsLoading(false);
						if (resposta instanceof Error)
							return throwError(() => resposta.message);
						console.log(resposta);
						return of({
							nome: resposta.nome,
							baseLegal: resposta.baseLegal,
							descricao: resposta.descricao,
							semDescricao: resposta.semDescricao,
							unidadeTempo: resposta.unidadeTempoID?.toString(),
							tempo: resposta.tempo?.toString()
						});
					}),
					tap(() => DesbloquearTela()),
				).subscribe({
					next: (formulario) => setFormulario(formulario),
					error: (erro) => ToastErro(erro)
				});
		});
	}, []);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Cadastrar Tipologia
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<FormGrid size={{ xs: 12 }}>
							<TextField
								id='nome'
								name='nome'
								type='text'
								placeholder='Nome da Tipologia'
								autoComplete='Nome'
								required
								value={formulario.nome}
								onChange={handleNome}
								label='Nome'
								helperText={validacao.nome.mensagem}
								error={!validacao.nome.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('nome')}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 12 }}>
							<TextField
								id='baseLegal'
								name='baseLegal'
								type='text'
								placeholder='Base Legal'
								autoComplete='Nome'
								required
								value={formulario.baseLegal}
								onChange={handleBaseLegal}
								label='Base Legal'
								helperText={validacao.baseLegal.mensagem}
								error={!validacao.baseLegal.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('baseLegal')}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 12 }}>
							<TextField
								id='descricao'
								name='descricao'
								type='text'
								placeholder='Descrição'
								autoComplete='Descrição'
								required={!formulario.semDescricao}
								disabled={formulario.semDescricao}
								multiline
								rows={5}
								maxRows={20}
								value={formulario.descricao}
								onChange={handleDescricao}
								label='Descrição'
								helperText={validacao.descricao.mensagem}
								error={!validacao.descricao.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('descricao')}
								size='small'
							/>
						</FormGrid>
						<LineGrid>
							<FormGrid>
								<FormControlLabel
									label="Sem Descrição"
									checked={formulario.semDescricao}
									control={
										<Checkbox
											onChange={() => handleSemDescricao(!formulario.semDescricao)}
										/>
									}
								/>
							</FormGrid>
						</LineGrid>
						<FormGrid size={{ xs: 6, md: 3 }}>
							<TextField
								id='unidadeTempo'
								name='unidadeTempo'
								type='number'
								placeholder='Unidade Tempo'
								autoComplete='Unidade Tempo'
								required
								value={formulario.unidadeTempo}
								onChange={handleUnidadeTempo}
								label='Unidade Tempo'
								select
								slotProps={{
									select: {
										native: true,
									},
								}}
								helperText={validacao.unidadeTempo.mensagem}
								error={!validacao.unidadeTempo.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('unidadeTempo')}
								size='small'
							>
								<option key={9999} value=''>&nbsp;</option>
								{unidadesTempo.map(({ unidadeTempoID, nome }) =>
									<option key={unidadeTempoID} value={unidadeTempoID}>
										<Typography sx={{ marginX: 5 }}>{nome}</Typography>
									</option>
								)}
							</TextField>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 3 }}>
							<TextField
								id='tempo'
								name='tempo'
								type='text'
								placeholder='Tempo'
								autoComplete='Tempo'
								required
								value={formulario.tempo}
								onChange={handleTempo}
								label='Tempo'
								helperText={validacao.tempo.mensagem}
								error={!validacao.tempo.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('tempo')}
								size='small'
							/>
						</FormGrid>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate('/tipologia')}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
				<Button
					color='success'
					disableElevation
					variant='contained'
					onClick={() => CadastrarTipologia()}
					startIcon={<Icon>save</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						salvar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};
