import React, { FC, useRef } from 'react';
import { IPopUpAwait, PopUpAwait } from 'shared/components';
import {
	createContext,
	useContext
} from 'use-context-selector';

interface IEsperaContexto {
	BloquearTela: () => void;
	DesbloquearTela: () => void;
}

const EsperaContexto = createContext<IEsperaContexto>({} as IEsperaContexto);

const EsperaProvider: FC<any> = ({ children }) => {
	const bloqueioRef = useRef<IPopUpAwait | null>(null);

	const BloquearTela = () => bloqueioRef.current?.bloquearTela();
	const DesbloquearTela = () => bloqueioRef.current?.desbloquearTela();

	return (
		<EsperaContexto.Provider value={{ BloquearTela, DesbloquearTela }}>
			<PopUpAwait ref={bloqueioRef} />
			{children}
		</EsperaContexto.Provider>
	);
};

const useEspera = (): IEsperaContexto => useContext<IEsperaContexto>(EsperaContexto);

export { EsperaContexto, EsperaProvider, useEspera };