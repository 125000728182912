import React, { FC, useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	TableHead,
	Icon,
	Button,
	styled,
	Divider,
	List,
	ListItem,
	ListSubheader
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { Empresa } from 'models';
import { EmpresaService } from 'shared/services/Empresas/EmpresasService';
import { useEspera, useTema } from 'contexts';
import { from, of, switchMap, tap, throwError } from 'rxjs';

import { ESituacao, EUnidadeTempo } from 'enums';
import { ToastErro } from 'utils/toast';
import { LineGrid, FormGrid } from 'shared/components';

export const VisualizarEmpresa: React.FC = () => {
	const { state } = useLocation();
	const { empresaID } = state;

	const { BloquearTela, DesbloquearTela } = useEspera();
	const {
		cores: { INFO }
	} = useTema();

	const [isLoading, setIsLoading] = useState(true);
	const [empresa, setEmpresa] = useState<Empresa>();
	const { debounce } = useDebounce(300, false);
	const navigate = useNavigate();

	useEffect(() => {
		debounce(() => {
			setIsLoading(true);
			BloquearTela();
			from(EmpresaService.getById(empresaID))
				.pipe(
					tap(() => setIsLoading(false)),
					switchMap((resposta) => {
						setIsLoading(false);
						if (resposta instanceof Error)
							return throwError(() => resposta.message);
						return of(new Empresa(resposta));
					}),
					tap(() => DesbloquearTela()),
				).subscribe({
					next: (formulario) => setEmpresa(formulario),
					error: (erro) => ToastErro(erro)
				});
		});
	}, []);

	const DadosCadastro = useCallback(() => (
		<>
			<Grid
				size={12}
				display='flex'
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				sx={{ mb: 3 }}
			>
				<Typography variant='h4'>
					Dados Cadastro
				</Typography>
			</Grid>
			<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
				<FormGrid size={{ xs: 12, md: 6 }}>
					<Typography fontWeight={600}>Usuario Cadastro</Typography>
					<Typography>{empresa?.usuarioNomeCadastro}</Typography>
				</FormGrid>
				<FormGrid size={{ xs: 12, md: 6 }}>
					<Typography fontWeight={600}>Data e Hora Cadastro</Typography>
					<Typography>{empresa?.dataHoraCadastro
						? new Date(`${empresa.dataHoraCadastro}+0000`).formatar()
						: 'Data não disponível'}</Typography>
				</FormGrid>
			</Grid>
		</>
	), [empresa]);

	const DadosAlteracao = useCallback(() => {
		if (empresa?.usuarioIDAlteracao)
			return (
				<>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Dados Alteração
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Usuario Alteracao</Typography>
							<Typography>{empresa?.usuarioNomeAlteracao}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Data e Hora Alteracao</Typography>
							<Typography>{empresa?.dataHoraCadastro
								? new Date(`${empresa.dataHoraAlteracao}+0000`).formatar()
								: 'Data não disponível'}</Typography>
						</FormGrid>
					</Grid>
				</>
			);
		return null;
	}, [empresa]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Visualizar Empresa
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<LineGrid>
							<FormGrid size={{ xs: 12, md: 4 }}>
								<Typography fontWeight={600}>CPF/CNPJ</Typography>
								<Typography>{empresa?.cpfcnpj?.formatarCPFOuCNPJ()}</Typography>
							</FormGrid>
						</LineGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Razão Social</Typography>
							<Typography>{empresa?.razaoSocial}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Nome Fantasia</Typography>
							<Typography>{empresa?.nomeFantasia}</Typography>
						</FormGrid>

						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Responsável</Typography>
							<Typography>{empresa?.responsavel}</Typography>
						</FormGrid>
						<LineGrid>
							<FormGrid size={{ xs: 6, md: 2 }}>
								<Typography fontWeight={600}>CEP</Typography>
								<Typography>{empresa?.cep?.formatarCEP()}</Typography>
							</FormGrid>
						</LineGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Logaradouro</Typography>
							<Typography>{empresa?.logradouro}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 2 }}>
							<Typography fontWeight={600}>Número</Typography>
							<Typography>{empresa?.numero}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 4 }}>
							<Typography fontWeight={600}>Bairro</Typography>
							<Typography>{empresa?.bairro}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Complemento</Typography>
							<Typography>{empresa?.complemento}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 2 }}>
							<Typography fontWeight={600}>Estado</Typography>
							<Typography>{empresa?.municipio.ufNome}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 3 }}>
							<Typography fontWeight={600}>Município</Typography>
							<Typography>{empresa?.municipio.nome}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 4, md: 3 }}>
							<Typography fontWeight={600}>Telefone Celular</Typography>
							<Typography>{empresa?.telefoneCelular?.formatarTelefone()}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 4, md: 3 }}>
							<Typography fontWeight={600}>Telefone Fixo</Typography>
							<Typography>{empresa?.telefoneFixo?.formatarTelefone()}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 4, md: 6 }}>
							<Typography fontWeight={600}>E-mail</Typography>
							<Typography>{empresa?.email}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 4, md: 6 }}>
							<Typography fontWeight={600}>Situação</Typography>
							<Typography>{ESituacao[empresa?.situacaoID || 0]}</Typography>
						</FormGrid>
					</Grid>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ my: 3 }}
					>
						<Typography variant='h4'>
							Tipologias Empresa
						</Typography>
					</Grid>
					<Grid size={12}>
						<Grid size={12}>
							<List
								sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden' }}
								component="nav"
								aria-labelledby="lista-tipologia"
								subheader={
									<ListSubheader sx={{ px: 1, borderRadius: 2, width: '100%' }} id="lista-tipologia">
										Tipologias
									</ListSubheader>
								}
								dense>
								{
									empresa?.tipologiasEmpresa?.map(({ tipologiaID, nome, unidadeTempoID, tempo }) =>
										<ListItem
											key={tipologiaID}
											sx={{ px: 1 }}>
											<LineGrid
												display='flex'
												flexDirection='row'
												justifyContent='center'
												alignItems='center'
												boxShadow='2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
												borderRadius={5}
												bgcolor={INFO.opacidade(15)}
												py={1}
												pl={1}
											>
												<Grid size={8} display='flex' alignItems='center'>
													<Typography>
														{nome}
													</Typography>
												</Grid>
												<Grid size={2}>
													<Typography fontWeight={600}>Unidade Tempo</Typography>
													<Typography>{EUnidadeTempo[unidadeTempoID || 0]}</Typography>
												</Grid>
												<Grid size={2}>
													<Typography fontWeight={600}>Tempo</Typography>
													<Typography>{tempo}</Typography>
												</Grid>
											</LineGrid>
										</ListItem>
									)
								}
							</List>
							<DadosCadastro />
							<DadosAlteracao />
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate('/empresa')}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};
