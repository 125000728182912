import React, { useEffect, useState, useCallback, useRef } from 'react';

type SessionTimerProps = {
	sessionTimeout: number;
	onTimeout: () => void;
};

export const ControleSessao: React.FC<SessionTimerProps> = ({ sessionTimeout, onTimeout }) => {
	const [remainingTime, setRemainingTime] = useState(sessionTimeout / 1000);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const lastActivityTimeRef = useRef<number>(Date.now());

	const resetTimer = useCallback(() => {
		lastActivityTimeRef.current = Date.now();
		setRemainingTime(sessionTimeout / 1000);
	}, [sessionTimeout]);

	const checkInactivity = useCallback(() => {
		const currentTime = Date.now();
		const timeElapsed = (currentTime - lastActivityTimeRef.current) / 1000;

		const newRemainingTime = sessionTimeout / 1000 - timeElapsed;
		setRemainingTime(newRemainingTime);

		if (newRemainingTime <= 0) {
			clearInterval(timeoutRef.current as NodeJS.Timeout);
			onTimeout();
		}
	}, [onTimeout, sessionTimeout]);

	useEffect(() => {
		const events = ['mousemove', 'keydown', 'click', 'touchstart'];
		const activityListener = () => resetTimer();

		events.forEach((event) => window.addEventListener(event, activityListener));

		timeoutRef.current = setInterval(checkInactivity, 1000);

		return () => {
			clearInterval(timeoutRef.current as NodeJS.Timeout);
			events.forEach((event) => window.removeEventListener(event, activityListener));
		};
	}, [checkInactivity, resetTimer]);

	return null;
};
