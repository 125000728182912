import { Api } from '../Axios';
interface IAuth {
	accessToken: string;
}

export interface IToken {
	access_token: string;
	token_type: string;
	expires_in: number;
}

export interface IAccess {
	nome_aplicacao: string;
	cod_aplicacao: number;
}

type TAccess = {
	data: IAccess[];
	totalCount: number;
}

export interface ICheckLogin {
	loginstatus: string;
}

type TCheckLogin = {
	data: ICheckLogin[];
}

const authorization = async (email: string, senha: string): Promise<IAuth | Error> => {
	try {
		const { data } = await Api.post('/auth/token', JSON.stringify({ email, senha: btoa(senha) }));

		if (data) {
			if (data?.data) {
				return data.data;
			} else {
				throw new Error(data.errors.join('<br/>'));
			}
		} else {
			throw new Error('Erro de token!');
		}
	} catch (error) {
		throw new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const getAccess = async (aplicacao = ''): Promise<TAccess | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!);

		const urlRelativa = '/auth/getAccess.php?accessToken=' + accessToken + '&aplicacao=' + aplicacao;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const checklogin = async (): Promise<TCheckLogin | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!);

		// Colocar criptografia depois!
		const url = `/auth/checklogin.php?accessToken=${accessToken}`;
		const { data } = await Api.get(url);

		if (data) {
			return { data };
		}
		return new Error('Erro de Login!');
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || 'Erro ao verificar login!!');
	}
};

export const AuthService = {
	authorization,
	getAccess,
	checklogin
};