const guidelineBaseWidth = 375;

export const LARGURA_TELA = window.innerWidth;
export const ALTURA_TELA = window.innerHeight;

export const ajustarEscala = (size: number) => {
	const scale = LARGURA_TELA / guidelineBaseWidth;
	return Math.round(size * scale);
};

export const scaleSize = (size: number) => (LARGURA_TELA / guidelineBaseWidth) * size;

export function boxShadow(color: any, offset = { height: 2, width: 2 },
	radius = 8, opacity = 0.2) {
	return {
		shadowColor: color,
		shadowOffset: offset,
		shadowOpacity: opacity,
		shadowRadius: radius,
		elevation: radius,
	};
}

export const LarguraPorcentagemDoDP = (larguraPercentagem: string, LARGURA = LARGURA_TELA) => {
	const elemWidth = typeof larguraPercentagem === 'number' ? larguraPercentagem : parseFloat(larguraPercentagem);
	return Math.round(LARGURA * elemWidth / 100);
};

export const AlturaPorcentagemDoDP = (alturaPercentagem: string, ALTURA = ALTURA_TELA) => {
	const elemHeight = typeof alturaPercentagem === 'number' ? alturaPercentagem : parseFloat(alturaPercentagem);
	return Math.round(ALTURA * elemHeight / 100);
};

export const FonteSize = (fontSize: number) => {
	switch (fontSize) {
		case 10: return AlturaPorcentagemDoDP('0.8%');
		case 12: return AlturaPorcentagemDoDP('1%');
		case 14: return AlturaPorcentagemDoDP('1.15%');
		case 16: return AlturaPorcentagemDoDP('1.3%');
		case 18: return AlturaPorcentagemDoDP('1.45%');
		case 20: return AlturaPorcentagemDoDP('1.6%');
		case 25: return AlturaPorcentagemDoDP('2.05%');
		case 30: return AlturaPorcentagemDoDP('2.45%');
		case 35: return AlturaPorcentagemDoDP('2.85%');
		case 40: return AlturaPorcentagemDoDP('3.25%');
		case 45: return AlturaPorcentagemDoDP('3.66%');
		case 50: return AlturaPorcentagemDoDP('4.05%');
	}
};

export const AJUSTE_BOTAO_FLUTUANTE = { paddingBottom: AlturaPorcentagemDoDP('8%') };
export const AJUSTE_BOTAO_FLUTUANTE_MARGIN = { marginTop: AlturaPorcentagemDoDP('8%') };

export const _500 = AlturaPorcentagemDoDP('40.6%');
export const _450 = AlturaPorcentagemDoDP('36.5%');
export const _400 = AlturaPorcentagemDoDP('32.45%');
export const _240 = AlturaPorcentagemDoDP('19.5%');
export const _200 = AlturaPorcentagemDoDP('16.25%');
export const _175 = AlturaPorcentagemDoDP('14.20%');
export const _160 = AlturaPorcentagemDoDP('13.05%');
export const _150 = AlturaPorcentagemDoDP('12.20%');
export const _130 = AlturaPorcentagemDoDP('10.4%');
export const _125 = AlturaPorcentagemDoDP('10%');
export const _110 = AlturaPorcentagemDoDP('8.95%');
export const _100 = AlturaPorcentagemDoDP('8.1%');
export const _90 = AlturaPorcentagemDoDP('7.3%');
export const _80 = AlturaPorcentagemDoDP('6.5%');
export const _75 = AlturaPorcentagemDoDP('6.1%');
export const _70 = AlturaPorcentagemDoDP('5.7%');
export const _60 = AlturaPorcentagemDoDP('4.95%');
export const _55 = AlturaPorcentagemDoDP('4.5%');
export const _50 = AlturaPorcentagemDoDP('4.1%');
export const _45 = AlturaPorcentagemDoDP('3.66%');
export const _40 = AlturaPorcentagemDoDP('3.25%');
export const _35 = AlturaPorcentagemDoDP('2.8%');
export const _30 = AlturaPorcentagemDoDP('2.45%');
export const _25 = AlturaPorcentagemDoDP('2.05%');
export const _20 = AlturaPorcentagemDoDP('1.6%');
export const _15 = AlturaPorcentagemDoDP('1.25%');
export const _10 = AlturaPorcentagemDoDP('0.8%');
export const _8 = AlturaPorcentagemDoDP('0.65%');
export const _6 = AlturaPorcentagemDoDP('0.48%');
export const _5 = AlturaPorcentagemDoDP('0.4%');
export const _3 = AlturaPorcentagemDoDP('0.21%');
export const _2 = AlturaPorcentagemDoDP('0.18%');
export const _1 = AlturaPorcentagemDoDP('0.09%');