import { TipologiaEmpresaRequest } from 'requests/TipologiaEmpresaRequest';
import { ESituacao } from '../enums';
import { Municipio } from './Municipio';
import { Perfil } from './Perfil';
import { Tipologia } from './Tipologia';

export class Empresa {
	empresaID: number;
	nomeFantasia: string;
	razaoSocial: string;
	responsavel: string;
	logradouro: string;
	numero: string;
	complemento?: string;
	cpfcnpj: string;
	cep: string;
	bairro: string;
	municipio: Municipio;
	telefoneCelular?: string;
	telefoneFixo?: string;
	email: string;
	situacaoID: ESituacao;
	matriz?: Empresa;
	usuarioIDCadastro?: number;
	usuarioNomeCadastro?: string;
	dataHoraCadastro?: string;
	usuarioIDAlteracao?: number;
	usuarioNomeAlteracao?: string;
	dataHoraAlteracao?: string;
	perfils?: Perfil[];
	tipologiasEmpresa: Tipologia[];

	constructor(dto?: any) {
		this.empresaID = dto?.empresaID;
		this.nomeFantasia = dto?.nomeFantasia;
		this.razaoSocial = dto?.razaoSocial;
		this.responsavel = dto?.responsavel;
		this.logradouro = dto?.logradouro;
		this.numero = dto?.numero;
		this.complemento = dto?.complemento;
		this.cpfcnpj = dto?.cpfcnpj;
		this.cep = dto?.cep;
		this.bairro = dto?.bairro;
		this.municipio = new Municipio(dto?.municipio);
		this.telefoneCelular = dto?.telefoneCelular;
		this.telefoneFixo = dto?.telefoneFixo;
		this.email = dto?.email;
		this.situacaoID = dto?.situacaoID as ESituacao;
		this.matriz = dto?.matriz ? new Empresa(dto?.matriz) : undefined;
		this.usuarioIDCadastro = dto?.usuarioIDCadastro;
		this.usuarioNomeCadastro = dto?.usuarioNomeCadastro;
		this.dataHoraCadastro = dto?.dataHoraCadastro;
		this.usuarioIDAlteracao = dto?.usuarioIDAlteracao;
		this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
		this.dataHoraAlteracao = dto?.dataHoraAlteracao;
		this.perfils = dto?.perfils.map((perfil: any) => new Perfil(perfil));
		this.tipologiasEmpresa = dto?.tipologiasEmpresa?.map((tipologia: any) => new Tipologia(tipologia)) || [];
	}
}