import { ESituacao, EUnidadeTempo } from 'enums';

export class Tipologia {
	tipologiaID: number;
	nome: string;
	situacaoID: ESituacao;
	vinculado: boolean;
	baseLegal: string;
	descricao: string;
	semDescricao: boolean;
	unidadeTempoID: EUnidadeTempo | undefined;
	tempo: number | string | undefined;
	usuarioIDCadastro?: number;
	usuarioNomeCadastro?: string;
	dataHoraCadastro?: string;
	usuarioIDAlteracao?: number;
	usuarioNomeAlteracao?: string;
	dataHoraAlteracao?: string;
	tipologiaEmpresaID: number;

	constructor(dto?: any) {
		this.tipologiaID = dto?.tipologiaID;
		this.nome = dto?.nome || dto?.tipologiaNome;
		this.situacaoID = dto?.situacaoID as ESituacao;
		this.vinculado = dto?.vinculado;
		this.baseLegal = dto?.baseLegal;
		this.descricao = dto?.descricao;
		this.semDescricao = dto?.semDescricao;
		this.unidadeTempoID = dto?.unidadeTempoID;
		this.tempo = dto?.tempo;
		this.usuarioIDCadastro = dto?.usuarioIDCadastro;
		this.usuarioNomeCadastro = dto?.usuarioNomeCadastro;
		this.dataHoraCadastro = dto?.dataHoraCadastro;
		this.usuarioIDAlteracao = dto?.usuarioIDAlteracao;
		this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
		this.dataHoraAlteracao = dto?.dataHoraAlteracao;
		this.tipologiaEmpresaID = dto?.tipologiaEmpresaID;
	}
}