import { from, map, Observable } from 'rxjs';
import { AxiosResponse } from 'axios';
import { Api } from './Axios';
import { ViaCEPResult } from 'models';

export const ObterEnderecoPorCEP = (cep: string): Observable<any> => {

	const url = process.env.REACT_APP_API_CEP_URL!.replace(/({cep})|({CEP})/g, cep);
	return from(Api.get(url))
		.pipe(
			map(({ data }: AxiosResponse<ViaCEPResult, any>) => ({
				cep: data.cep,
				logradouro: data.logradouro,
				bairro: data.bairro,
				localidade: data.localidade,
				ibge: data.ibge,
				gia: data.gia,
				ddd: data.ddd,
				siafi: data.siafi,
				complemento: data.complemento,
				estado: data.uf,
				municipio: data.ibge
			}))
		);
};