import { TipologiaEmpresaRequest } from './TipologiaEmpresaRequest';

export class EmpresaRequest {
	empresaID: number;
	nomeFantasia: string;
	razaoSocial: string;
	responsavel: string;
	logradouro: string;
	numero: string;
	complemento: string;
	cpfcnpj: string;
	cep: string;
	bairro: string;
	municipioID: number;
	ufid: number;
	telefoneCelular: string;
	telefoneFixo: string;
	email: string;
	tipologiasEmpresa: TipologiaEmpresaRequest[];

	constructor(dto?: any) {
		this.empresaID = dto?.empresaID;
		this.nomeFantasia = dto?.nomeFantasia;
		this.razaoSocial = dto?.razaoSocial;
		this.responsavel = dto?.responsavel;
		this.logradouro = dto?.logradouro;
		this.numero = dto?.numero;
		this.complemento = dto?.complemento;
		this.cpfcnpj = dto?.cpfcnpj;
		this.cep = dto?.cep;
		this.bairro = dto?.bairro;
		this.municipioID = dto?.municipioID;
		this.ufid = dto?.ufid;
		this.telefoneCelular = dto?.telefoneCelular;
		this.telefoneFixo = dto?.telefoneFixo;
		this.email = dto?.email;
		this.tipologiasEmpresa = dto?.tipologiasEmpresa?.map((tipologiaEmpresa: TipologiaEmpresaRequest) => new TipologiaEmpresaRequest(tipologiaEmpresa)) || [];
	}
}