import React, { forwardRef, ForwardRefExoticComponent, RefAttributes, useImperativeHandle, useState } from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import { useTema } from 'contexts';

export interface IPopUpAwait {
	bloquearTela: () => void;
	desbloquearTela: () => void;
}

export const PopUpAwait: ForwardRefExoticComponent<Omit<any, 'ref'> & RefAttributes<any>> =
	forwardRef((_, ref) => {
		const [bloqueado, setBloqueado] = useState(false);
		const {
			cores: { FUNDO, CONTRASTE, INFO },
			tamanhoFontes: { FONTE_PEQUENA }
		} = useTema();

		useImperativeHandle<any, IPopUpAwait>(ref, () => ({
			bloquearTela() {
				setBloqueado(true);
			},
			desbloquearTela() {
				setBloqueado(false);
			}
		}));

		return (
			<Modal
				open={bloqueado}
				closeAfterTransition
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					zIndex: 99999,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: FUNDO,
						borderRadius: 2,
						padding: 2,
						width: 200,
					}}
				>
					<CircularProgress size={40} sx={{ color: INFO }} />
					<Typography
						variant='body2'
						sx={{
							marginTop: 1,
							color: CONTRASTE,
							fontSize: FONTE_PEQUENA,
							textAlign: 'center',
						}}
					>
						Aguarde...
					</Typography>
				</Box>
			</Modal>
		);
	});

PopUpAwait.displayName = 'PopUpAwait';