import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import { ToastContainer, toast } from 'react-toast';
// import { Bounce, ToastContainer } from 'react-toastify';
import { AppThemeProvider, AuthProvider, EsperaProvider, MensagemProvider, TemaProvider } from './contexts';

import 'react-toastify/dist/ReactToastify.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import { MenuProvider } from './contexts/MenuContext';
import { ControleSessao } from 'shared/components';

export const App = () => {

	const handleTimeout = () => {
		localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!);
	};

	return (
		<AuthProvider>
			<AppThemeProvider>
				<BrowserRouter>
					<MenuProvider>
						<EsperaProvider>
							<MensagemProvider>
								<AppRoutes />
								<ToastContainer delay={3000} position='bottom-right' />
								<ControleSessao sessionTimeout={54000000} onTimeout={handleTimeout} />
							</MensagemProvider>
						</EsperaProvider>
					</MenuProvider>
				</BrowserRouter>
			</AppThemeProvider>
		</AuthProvider>
	);
};
// export const App = () => {
// 	return (
// 		<BrowserRouter>
// 			<AppRoutes />
// 		</BrowserRouter>
// 	);
// };