import React, { createContext, useContext, useEffect, useState } from 'react';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { TemaClaro, TemaEscuro } from 'styles/theme';

const Tamanhosfontes = {
	FONTE_HIPER: 32,
	FONTE_POWER: 28,
	FONTE_ULTRA: 24,
	FONTE_MEGA: 20,
	FONTE_SUPER: 18,
	FONTE_GRANDE: 16,
	FONTE_MEDIA: 14,
	FONTE_PEQUENA: 12,
	FONTE_MINI: 10,
	FONTE_MICRO: 8,
	FONTE_NANO: 6,
};

// Interfaces
interface ITamanhoFontes {
	FONTE_HIPER: number;
	FONTE_POWER: number;
	FONTE_ULTRA: number;
	FONTE_MEGA: number;
	FONTE_SUPER: number;
	FONTE_GRANDE: number;
	FONTE_MEDIA: number;
	FONTE_PEQUENA: number;
	FONTE_MINI: number;
	FONTE_MICRO: number;
	FONTE_NANO: number;
}

interface ITemaContexto {
	estaClaro: boolean;
	nome: PaletteMode;
	cores: typeof TemaClaro.cores;
	tamanhoFontes: ITamanhoFontes;
	mudarTema: () => void;
}

const TemaContexto = createContext<ITemaContexto>({} as ITemaContexto);

// Provider
const TemaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [tema, setTema] = useState(TemaClaro);
	const [escala, setEscala] = useState(1);

	useEffect(() => {
		const temaSalvo = localStorage.getItem('entrada@tema');
		if (temaSalvo) {
			setTema(temaSalvo === TemaEscuro.nome ? TemaEscuro : TemaClaro);
		}
	}, []);

	const mudarTema = () => {
		setTema((atual) => {
			const novoTema = atual.nome === TemaClaro.nome ? TemaEscuro : TemaClaro;
			localStorage.setItem('entrada@tema', novoTema.nome);
			return novoTema;
		});
	};

	const tamanhoFontes: ITamanhoFontes = {
		FONTE_HIPER: Tamanhosfontes.FONTE_HIPER * escala,
		FONTE_POWER: Tamanhosfontes.FONTE_POWER * escala,
		FONTE_ULTRA: Tamanhosfontes.FONTE_ULTRA * escala,
		FONTE_MEGA: Tamanhosfontes.FONTE_MEGA * escala,
		FONTE_SUPER: Tamanhosfontes.FONTE_SUPER * escala,
		FONTE_GRANDE: Tamanhosfontes.FONTE_GRANDE * escala,
		FONTE_MEDIA: Tamanhosfontes.FONTE_MEDIA * escala,
		FONTE_PEQUENA: Tamanhosfontes.FONTE_PEQUENA * escala,
		FONTE_MINI: Tamanhosfontes.FONTE_MINI * escala,
		FONTE_MICRO: Tamanhosfontes.FONTE_MICRO * escala,
		FONTE_NANO: Tamanhosfontes.FONTE_NANO * escala,
	};

	const temaMaterialUI = createTheme({
		palette: {
			mode: tema.nome,
			background: {
				default: tema.cores.FUNDO,
			},
			text: {
				primary: tema.cores.CONTRASTE,
			},
			primary: {
				main: tema.cores.INFO,
			},
		},
	});

	return (
		<TemaContexto.Provider
			value={{
				estaClaro: tema.nome === TemaClaro.nome,
				nome: tema.nome,
				cores: tema.cores,
				tamanhoFontes,
				mudarTema,
			}}
		>
			<ThemeProvider theme={temaMaterialUI}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</TemaContexto.Provider>
	);
};

// Hook
const useTema = (): ITemaContexto => {
	const context = useContext(TemaContexto);
	if (!context) {
		throw new Error('useTema deve ser usado dentro de um TemaProvider');
	}
	return context;
};

export { TemaProvider, useTema };
