import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	TableHead,
	Icon,
	Button,
	TablePagination,
	TableSortLabel,
	ThemeProvider
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { Setor } from 'models';
import { EOrdenacao, ESituacao } from 'enums';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { visuallyHidden } from '@mui/utils';
import { createTheme, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';

import { useEmpresaSelecionadaContext, useEspera, useMensagem } from 'contexts';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { AlterButtonWithIcon, DeleteButtonWithIcon, ViewButtonWithIcon } from 'shared/components/buttons';
import { SetorService } from 'shared/services/Setores/SetoresService';

interface ISetorTable {
	setorID: number;
	nome: string;
	usuarioNomeCadastro: string;
	situacao: string;
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof ISetorTable;
	label: string;
	left: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'setorID',
		left: true,
		disablePadding: true,
		label: 'ID',
	},
	{
		id: 'nome',
		left: true,
		disablePadding: false,
		label: 'Nome',
	},
	{
		id: 'usuarioNomeCadastro',
		left: false,
		disablePadding: false,
		label: 'Usuário Cadastro',
	},
	{
		id: 'situacao',
		left: false,
		disablePadding: false,
		label: 'Situação',
	},
];

interface EnhancedTableProps {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ISetorTable) => void;
	order: EOrdenacao;
	orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler =
		(property: keyof ISetorTable) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {

					const orderDirection = EOrdenacao[order].toLowerCase() as 'asc' | 'desc';
					const sortDirection = orderBy === headCell.id ? orderDirection : false;
					const direction = orderBy === headCell.id ? orderDirection : 'asc';
					const iconName = order == EOrdenacao.DESC ? 'sorted descending' : 'sorted ascending';

					return (
						<TableCell
							key={headCell.id}
							align={headCell.left ? 'left' : 'center'}
							padding='normal'
							sortDirection={sortDirection}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={direction}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{iconName}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					);
				})}

				<TableCell
					key={9999}
					align='center'
					padding='normal'
				>
					&nbsp;
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export const Setores: React.FC = () => {
	const { debounce } = useDebounce(300, false);
	const [rowsSetors, setRowsSetors] = useState<ISetorTable[]>([]);
	const navigate = useNavigate();

	const [ordenarPor, setOrdenarPor] = useState<EOrdenacao>(EOrdenacao.ASC);
	const [campoOrdenacao, setCampoOrdenacao] = useState<keyof ISetorTable>('setorID');
	const [pagina, setPagina] = useState(0);
	const [total, setTotal] = useState(0);
	const [qtdPagina, setQtdPagina] = useState(parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!));
	const theme = useTheme();
	const { Informacao } = useMensagem();
	const { BloquearTela, DesbloquearTela } = useEspera();
	const { empresaSelecionada } = useEmpresaSelecionadaContext();


	const handleRequestSort = (
		event: MouseEvent<unknown>,
		property: keyof ISetorTable,
	) => {
		const isAsc = campoOrdenacao === property && ordenarPor === EOrdenacao.ASC;
		setOrdenarPor(isAsc ? EOrdenacao.DESC : EOrdenacao.ASC);
		setCampoOrdenacao(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPagina(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQtdPagina(parseInt(event.target.value));
		setPagina(0);
	};

	const emptyRows =
		pagina > 0 ? Math.max(0, (1 + pagina) * (qtdPagina - rowsSetors.length)) : 0;

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales['ptBR']),
		[theme],
	);

	const getSetors = useCallback(() => {
		BloquearTela();
		from(SetorService.getWithPagination(pagina, qtdPagina, campoOrdenacao, ordenarPor, { empresaID: empresaSelecionada?.empresaID }))
			.pipe(
				switchMap((retorno: any | Error) => {
					if (retorno instanceof Error)
						return throwError(() => retorno);

					const { setors, total } = retorno;

					return of({
						Setors: setors
							.map(({
								setorID,
								nome,
								usuarioNomeCadastro,
								situacaoID
							}: Setor) => ({
								setorID,
								nome,
								usuarioNomeCadastro,
								situacao: ESituacao[situacaoID]
							} as ISetorTable)),
						total
					});
				}),
				tap(() => DesbloquearTela()),
			)
			.subscribe({
				next: ({ Setors, total }: any) => {
					setRowsSetors(Setors);
					setTotal(total);
				},
				error: (error) => console.log(error)
			});
	}, [pagina, qtdPagina, campoOrdenacao, ordenarPor]);

	const InativarSetor = useCallback((setorID: number, nomeFantasia: string) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja inativar a Setor <Typography fontWeight={700}>&nbsp;{nomeFantasia}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						BloquearTela();
						from(SetorService.inative(setorID))
							.pipe(
								switchMap((retorno: boolean | Error) => {
									if (retorno instanceof Error)
										return throwError(() => retorno.message);
									return of(retorno);
								}),
								tap(() => {
									getSetors();
									DesbloquearTela();
								})
							)
							.subscribe({
								next: () => ToastSucesso(`Setor inativada com sucesso`),
								error: (erro) => ToastErro(erro),
							});
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	}, []);

	useEffect(() => {
		debounce(() => {
			getSetors();
		});
	}, [pagina, qtdPagina, campoOrdenacao, ordenarPor]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						display='flex'
						flexDirection='row'
						size={12}
					>
						<Grid size={3} />
						<Grid
							size={8}
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='center'
						>
							<Typography variant='h4'>
								Setor
							</Typography>
						</Grid>
						<Grid
							size={3}
							container
							sx={{
								padding: '1rem',
								justifyContent: 'end',
							}}>
							<Button
								color='success'
								disableElevation
								variant='contained'
								onClick={() => navigate('/cadastrarSetor')}
								startIcon={<Icon>add</Icon>}
								sx={{ marginLeft: 2 }}>
								<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
									Cadastrar
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid size={12}>
						<ThemeProvider theme={themeWithLocale}>
							<TableContainer>
								<Table
									sx={{ minWidth: 750 }}
									aria-labelledby='tableTitle'
									size='small'
								>
									<EnhancedTableHead
										order={ordenarPor}
										orderBy={campoOrdenacao}
										onRequestSort={handleRequestSort}
									/>
									<TableBody>
										{rowsSetors.map(({
											setorID,
											nome,
											usuarioNomeCadastro,
											situacao
										}) => {
											return (
												<TableRow hover key={setorID}>
													<TableCell align='left'>{setorID}</TableCell>
													<TableCell align='left'>{nome}</TableCell>
													<TableCell align='center'>{usuarioNomeCadastro}</TableCell>
													<TableCell align='center'>{situacao}</TableCell>
													<TableCell align='center' width='15%'>
														<DeleteButtonWithIcon onClick={() => InativarSetor(setorID, nome)} />
														<AlterButtonWithIcon onClick={() => navigate('/alterarSetor', { state: { setorID } })} />
														<ViewButtonWithIcon onClick={() => navigate('/visualizarSetor', { state: { setorID } })} />
													</TableCell>
												</TableRow>
											);
										})}
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: 33 * emptyRows,
												}}
											>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								component='div'
								count={total}
								rowsPerPage={qtdPagina}
								page={pagina}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</ThemeProvider>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'start' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate('/')}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};
