import { plainToClass } from 'class-transformer';
import { UF } from './UF';

export class Municipio {
	municipioID?: number;
	nome?: string;
	ufid?: number;
	ufSigla?: string;
	ufNome?: string;
	uf?: UF;

	constructor(dto: any) {
		this.municipioID = dto?.municipioID;
		this.nome = dto?.nome;
		this.ufid = dto?.ufid;
		this.ufSigla = dto?.ufSigla;
		this.ufNome = dto?.ufNome;
	}

	static create(municipio: any): Municipio {
		return plainToClass(Municipio, {
			municipioID: municipio?.MunicipioID,
			nome: municipio?.Nome,
			ufSigla: municipio?.UFSigla,
			uf: UF.create(municipio.UF),
		});
	}
}