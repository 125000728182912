import { Empresa } from 'models';
import { ESituacao, EOrdenacao, EUnidadeTempo } from 'enums';
import { Api } from '../Axios';
import { ObjectToQuery } from 'utils';
import { EmpresaRequest } from 'requests';

export interface IMunicipio {
	municipioID: number;
	ufid: number;
	ufSigla: string;
	ufNome: string;
}
export interface IListagemEmpresa {
	empresaID: number;
	nomeFantasia: string;
	bairro: string;
	cep: string;
	cpfcnpj: string;
	dataHoraAlteracao: string;
	dataHoraCadastro: string;
	email: string;
	logradouro: string;
	municipio: IMunicipio;
	numero: string;
	complemento: string;
	perfils: any[];
	razaoSocial: string;
	responsavel: string;
	telefoneCelular: string;
	telefoneFixo: string;
	situacaoID: ESituacao;
	usuarioIDCadastro: string;
	usuarioNomeCadastro: string;
	usuarioIDAlteracao: string;
	usuarioNomeAlteracao: string;
}

export interface IDetalheEmpresa {
	empresaID: number;
	nomeFantasia: string;
	bairro: string;
	cep: string;
	cpfcnpj: string;
	dataHoraAlteracao: string;
	dataHoraCadastro: string;
	email: string;
	logradouro: string;
	municipio: IMunicipio;
	numero: string;
	complemento: string;
	perfils: any[];
	razaoSocial: string;
	responsavel: string;
	telefoneCelular: string;
	telefoneFixo: string;
	situacaoID: ESituacao;
	usuarioIDCadastro: string;
	usuarioNomeCadastro: string;
	usuarioIDAlteracao: string;
	usuarioNomeAlteracao: string;
	tipologiasEmpresa: ITipologiaEmpresa[];
}

export interface ITipologiaEmpresa {
	tipologiaEmpresaID: number;
	tipologiaID: number;
	tipologiaNome: string;
	situacaoID: ESituacao;
	unidadeTempoID: EUnidadeTempo;
	tempo: number;
	usuarioIDCadastro: number;
	dataHoraCadastro: string;
	usuarioNomeCadastro: string;
	usuarioIDAlteracao: number;
	dataHoraAlteracao: string;
	usuarioNomeAlteracao: string;
}

type TEmpresasComTotal = {
	empresas: Empresa[];
	total: number;
}

type TEmpresasSomenteTotalCount = {
	totalCount: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TEmpresasSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = `/Empresa/ObterQtdeTotal`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (page = 1, filter = ''): Promise<Empresa[] | Error> => {
	try {
		const urlRelativa = `/Empresa/ObterTodos`;
		const { data } = await Api.get(urlRelativa);

		if (data)
			return data.data;

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getWithPagination = async (pagina: number = 1, qtdLinha: number = 10, campoOrdenacao: string, ordenacaoPor: EOrdenacao = EOrdenacao.ASC, filtros: any = {}): Promise<TEmpresasComTotal | Error> => {
	try {
		const queryParams = ObjectToQuery({
			pagina: pagina + 1,
			qtdLinha,
			ordenacaoPor,
			campoOrdenacao,
			...filtros
		});
		const urlRelativa = `/Empresa/ObterPaginado?${queryParams}`;
		const { data } = await Api.get(urlRelativa);

		if (data && data.data)
			return {
				empresas: data.data.lista?.map((empresa: any) => new Empresa(empresa)),
				total: data.data.total
			};

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<IDetalheEmpresa | Error> => {
	try {
		const { data } = await Api.get(`/Empresa/ObterPorId?empresaID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const save = async (empresa: EmpresaRequest): Promise<Empresa[] | Error> => {
	try {
		const urlRelativa = `/Empresa`;
		const { data } = await Api.post(urlRelativa, empresa);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao cadastrar empresa.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const update = async (empresa: EmpresaRequest): Promise<Empresa | Error> => {
	try {
		const urlRelativa = `/Empresa/Alterar`;
		const { data } = await Api.post(urlRelativa, empresa);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao alterar empresa.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const inative = async (empresaID: number): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Empresa/Inativar`;
		const { data } = await Api.post(urlRelativa, { empresaID });

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao inativar empresa.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};


export const EmpresaService = {
	getTotal,
	getAll,
	getById,
	getWithPagination,
	save,
	update,
	inative
};