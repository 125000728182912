import axios, { InternalAxiosRequestConfig } from 'axios';
import { errorInterceptor, responseInterceptor } from './interceptors';
import { Usuario } from 'models';

const Api = axios.create({
	baseURL: process.env.REACT_APP_URL_BASE,
	adapter: 'fetch',
	timeout: 360000,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	}
});

Api.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error),
);

Api.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		const jsonUsuarioString = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!);
		if (!jsonUsuarioString || config.url?.includes('auth/token'))
			return config;
		const { email, senha } = JSON.parse(jsonUsuarioString);

		if (!email || !senha) {
			localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!);
			return config;
		}

		try {
			const { data } = await Api.post('/auth/token', JSON.stringify({ email: email.descripitografar(), senha: btoa(senha.descripitografar()) }));

			if (data) {
				if (data?.data) {
					const { accessToken, tokenType } = data.data;
					config.headers.setAuthorization(`${tokenType} ${accessToken}`);
				} else
					throw new Error(data.errors.join('<br/>'));
			} else
				throw new Error('Erro de token!');
		} catch (error) {
			throw new Error((error as { message: string }).message || 'Erro ao autenticar!!');
		}
		return config;
	},
	error => error,
);

export { Api };