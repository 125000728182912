import {
	Paper,
	Icon,
	Box,
	Typography,
	Grid2 as Grid,
	Card,
	Button,
	List,
	ListSubheader,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgressCustom, FerramentasDaListagem } from 'shared/components';
import { LayoutBaseDePagina } from 'shared/layouts';
import { TipologiaService } from 'shared/services/Tipologias/TipologiasService';

import './mapLinks.css';
import { useEmpresaSelecionadaContext } from 'contexts';
import { from, of, switchMap, throwError } from 'rxjs';

import { Tipologia } from 'models';
import { ToastErro } from 'utils/toast';
import { ListaDocumentos } from 'shared/components/listagemDocumentos';


export const ListagemDeDocumentosAvulsos: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [tipologias, setTipologias] = useState<Tipologia[]>([]);
	const [tipologiaId, setTipologiaId] = useState(searchParams.get('tipologiaId') || '');
	const [tipologia, setTipologia] = useState<Tipologia | undefined>();

	const { empresaSelecionada } = useEmpresaSelecionadaContext();

	const navigate = useNavigate();

	const busca = useMemo(() => {
		return searchParams.get('busca') || '';
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get('pagina') || '1');
	}, [searchParams]);

	useEffect(() => {
		from(TipologiaService.getAll(empresaSelecionada.empresaID))
			.pipe(
				switchMap((resultado) => {
					if (resultado instanceof Error)
						return throwError(() => resultado.message);
					return of(resultado.data);
				})
			).subscribe({
				next: (tipologias) => {
					setTipologias(tipologias);
				},
				error: (error) => ToastErro(error || 'Erro ao buscar tipologias')
			});
	}, []);

	useEffect(() => {
		if (tipologias.length)
			setTipologia(tipologias.find(({ tipologiaID }) => tipologiaID === +tipologiaId));
	}, [tipologias, tipologiaId]);

	useEffect(() => {
		const tipologiaId = searchParams.get('tipologiaId');
		if (tipologiaId)
			setTipologiaId(tipologiaId);
	}, [searchParams]);

	const handlerChangeTipologia = (tipologiaId: string) => {
		setTipologiaId(tipologiaId);
		setSearchParams({ busca, pagina: '1', tipologiaId }, { replace: true });
	};

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={1}>
						<Grid size={2}>
							<List
								sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden' }}
								component="nav"
								aria-labelledby="lista-tipologia"
								subheader={
									<ListSubheader sx={{ px: 1, borderRadius: 2, width: '100%' }} id="lista-tipologia">
										Tipologias
									</ListSubheader>
								}
								dense
							>
								<CircularProgressCustom loadding={!tipologias.any()}>
									{
										tipologias.map(({ tipologiaID, nome }) =>
											<Tooltip title={nome} arrow placement='right' key={tipologiaID}>
												<ListItemButton
													sx={{ px: 1 }}
													selected={tipologiaId == tipologiaID.toString()}
													onClick={() => handlerChangeTipologia(tipologiaID.toString())}>
													<ListItemIcon sx={{ minWidth: 30 }}>
														<Icon>title</Icon>
													</ListItemIcon>
													<ListItemText primary={nome} sx={{ whiteSpace: 'nowrap' }} />
												</ListItemButton>
											</Tooltip>
										)
									}
								</CircularProgressCustom>
							</List>
						</Grid>
						<Grid size={10} component={Paper} borderRadius={2} p={1}>
							<Box className='mapFolder' p={2}>
								<Box className='mapMain'>
									<Box className='mapLink' onClick={() => navigate('/pesquisar')}>Home</Box>
									<Box className='mapLink' onClick={() => navigate('/pesquisar')}>&nbsp;/ Tipologia</Box>&nbsp;
								</Box>
							</Box>
							<FerramentasDaListagem
								mostrarInputBusca={true}
								mostrarBotaoVoltar={false}
								mostrarBotaoNovo={false}
								textoBotaoNovo='Novo'
								textoDaBusca={searchParams.get('busca') ?? ''}
								tipologiaBuscaIndexado={tipologiaId}
								aoClicarEmNovo={() => navigate('/pesquisar')}
								marginX={0}
								padding={0}
								aoMudarTextoDeBusca={texto => {
									setSearchParams({ busca: texto, pagina: '1', tipologiaId: tipologiaId }, { replace: true });
								}}
							/>
							<CircularProgressCustom loadding={!tipologias.any()}>
								<Box display='flex' p={1} width={300}>
									<Box p={1} flexGrow={1}>
										<Card variant='outlined' sx={{
											boxShadow: 1,
											borderRadius: 1,
											p: 1,
											minWidth: 150,
											maxWidth: 200,
										}} >
											<Typography key={0} whiteSpace='nowrap' fontSize={10} align='left' color='#085784' fontWeight='bold'>
												{tipologia?.nome}
											</Typography>
										</Card>
									</Box>
									<Box p={1}>
										<Box flex={1} textAlign={'left'}>
											<Button
												color='info'
												disableElevation
												variant='contained'
												onClick={() => navigate('/pesquisar')}
												startIcon={<Icon>add</Icon>}
											>Novo</Button>
										</Box>
									</Box>
								</Box>
							</CircularProgressCustom>
							<ListaDocumentos
								busca={busca}
								pagina={pagina}
								tipologiaId={tipologiaId}
								botaoDownload
								botaoCancelar
								onChangePagination={(novaPagina: number) => setSearchParams({ busca: busca, pagina: novaPagina.toString(), tipologiaId: tipologiaId }, { replace: true })}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};