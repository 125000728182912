import React, { useEffect, useRef, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	IconButton,
	TextField,
	CircularProgress,
	Box,
	Typography,
	Stack,
	TableHead,
	TableFooter,
	Icon,
	Button
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid2';
import { useAutenticacaoContext } from 'contexts';
import { LayoutBaseDePagina } from 'shared/layouts';
import { ChatIAService, IChatIA } from 'shared/services/ChatIA/ChatIAService';
import { Mensagem } from 'models';
import { ERole } from 'enums';
import { from, Observable, tap } from 'rxjs';
import BlockUi from 'react-block-ui';
import { useNavigate } from 'react-router-dom';


const LIMITE_MENSAGENS_CHAT_IA = parseInt(process.env.REACT_APP_LIMITE_MENSAGENS_CHAT_IA!);

export const ChatIA: React.FC = () => {
	const [mensagens, setMensagens] = useState<Mensagem[]>([]);
	const [pergunta, setPergunta] = useState('');
	const [carregando, setCarregando] = useState(false);

	const { usuarioLogado } = useAutenticacaoContext();
	const navigate = useNavigate();

	const messagesEndRef = useRef<any>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
	}, [mensagens]);

	const sendMessage = () => {
		if (pergunta.trim() === '') return;

		const _mensagens = [...mensagens];

		_mensagens.push(new Mensagem({
			user: usuarioLogado?.nome,
			role: ERole.USUARIO,
			content: pergunta,
			timestamp: new Date(),
			backgroundColor: '#DCF8C6',
			justifyContent: 'flex-end'
		}));
		setMensagens((anteriores: any[]) => [...anteriores, _mensagens.at(-1)]);
		setPergunta('');
		setCarregando(true);

		doAQuestionToIA(_mensagens)
			.subscribe({
				error: (error) => {
					console.error('Erro ao obter resposta da IA:', error);
					setCarregando(false);
				},
				complete: () => setCarregando(false)
			});
	};

	const doAQuestionToIA = (_perguntaOuMensagens?: string | Mensagem[]): Observable<IChatIA> => {
		let chatMensagens: Mensagem[] = [];
		if (Array.isArray(_perguntaOuMensagens))
			chatMensagens = _perguntaOuMensagens;
		if (typeof _perguntaOuMensagens === 'string')
			chatMensagens = [new Mensagem({ role: ERole.USUARIO, content: _perguntaOuMensagens })];

		return from(ChatIAService.doAQuestionToIAWithMessagens(chatMensagens))
			.pipe(
				tap(({ resposta }) => {
					const aiMessage = new Mensagem({
						user: 'Assitente',
						content: resposta,
						role: ERole.ASSISTENTE,
						timestamp: new Date(),
						backgroundColor: '#E5E5EA',
						justifyContent: 'flex-start'
					});
					setMensagens((anteriores: Mensagem[]) => [...anteriores, aiMessage]);
				})
			);
	};


	useEffect(() => {
		setCarregando(true);
		doAQuestionToIA('Olá, Quais suas instruções?')
			.subscribe({
				error: (error) => {
					console.error('Erro ao obter resposta da IA:', error);
					setCarregando(false);
				},
				complete: () => setCarregando(false)
			});
	}, []);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<TableContainer component={Paper} variant='outlined' sx={{ border: 0 }}>
					<Table sx={{ border: 0 }}>
						<TableHead>
							<TableRow>
								<TableCell colSpan={2} sx={{ border: 0 }}>
									<Typography variant='h4' display='flex' justifyContent='center' alignItems='center' gutterBottom marginBottom={0}>
										Chat com IA
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody sx={{ border: 0 }}>
							<TableRow>
								<TableCell>
									<Paper elevation={3} sx={{ padding: 2, height: '70vh', overflowY: 'auto', display: 'flex', flexDirection: 'column', marginX: '10vw' }}>
										<Box flexGrow={1} padding={2} sx={{ overflowY: 'auto' }}>
											{mensagens.map((message: any, index: number) => (
												<Box
													key={index}
													display='flex'
													justifyContent={message.justifyContent}
													marginBottom={1}
												>
													<Paper
														elevation={2}
														sx={{
															padding: 1,
															maxWidth: '60%',
															borderRadius: 2,
															backgroundColor: message.backgroundColor,
														}}
													>
														<Typography variant='body2' color='textSecondary' fontWeight='bold' marginBottom={.5} marginTop={.5} fontSize={16}>
															{message.user}
														</Typography>
														<Paper sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }} elevation={0}>
															<Typography variant='body1' flexGrow={.3} sx={{ wordWrap: 'break-word' }}>
																{message.content}
															</Typography>
															<Typography variant='caption' color='textSecondary' display='flex' textAlign='right' marginTop={.5} fontSize={11} flexGrow={.7} justifyContent='center' alignItems='end' paddingLeft={2} sx={{ opacity: .5 }}>
																{new Date(message.timestamp).toLocaleTimeString().replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2')}
															</Typography>
														</Paper>
													</Paper>
												</Box>
											))}


											<BlockUi blocking={carregando}></BlockUi>
											<div ref={messagesEndRef} />
										</Box>
									</Paper>

									<Stack direction='row' spacing={1} mt={2} marginX='10vw'>
										<TextField
											disabled={carregando}
											label='Como posso ajudar?'
											variant='outlined'
											fullWidth
											value={pergunta}
											onChange={(e) => setPergunta(e.target.value)}
											onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
										/>
										<Button
											disabled={carregando}
											color='success'
											disableElevation
											variant='contained'
											onClick={sendMessage} >
											<IconButton color='inherit' onClick={sendMessage}>
												<SendIcon />
											</IconButton>
										</Button>
									</Stack>

								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer >
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};
