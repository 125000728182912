import React, { useEffect, useMemo, useState } from 'react';
import {
	Paper,
	Box,
	Typography,
	Icon,
	Button,
	TextField,
	TextFieldVariants,
	List,
	ListItem,
	ListSubheader,
	Autocomplete
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { DadosFormulario, ValidadorEmpresa } from 'validators/Empresa';
import { Validacao, ValidacaoTipologia } from 'validators/Validador';
import { Municipio, Tipologia, UF } from 'models';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { ObterEnderecoPorCEP } from 'shared/services/Diversos';
import { MUNICIPIOS_JSON, UFS_JSON } from 'json';
import { useEspera, useMensagem, useTema } from 'contexts';
import { EmpresaService } from 'shared/services/Empresas/EmpresasService';
import { EmpresaRequest } from 'requests/EmpresaRequest';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { LineGrid, FormGrid } from 'shared/components';
import { ESituacao, EUnidadeTempo } from 'enums';
import { DefaultButtonOnolyIcon } from 'shared/components/buttons';
import { TipologiaService } from 'shared/services/Tipologias/TipologiasService';
import { EnumToArray } from 'utils';

export const AlterarEmpresa: React.FC = () => {
	const UFs = useMemo(() => UFS_JSON.map(uf => UF.create(uf)), []);

	const Municipios = useMemo(() => MUNICIPIOS_JSON.map(municipio => {
		const municipioModel = Municipio.create(municipio);
		municipioModel.uf = UFs.find(({ sigla }) => sigla == municipioModel.ufSigla)!;
		return municipioModel;
	}), []);

	const unidadesTempo = useMemo(() => EnumToArray(EUnidadeTempo).map(({ id, name }) => ({ unidadeTempoID: id, nome: name })), []);

	const { state } = useLocation();
	const { empresaID } = state;

	const navigate = useNavigate();

	const [formulario, setFormulario] = useState<DadosFormulario>({
		cpfCnpj: '',
		razaoSocial: '',
		nomeFantasia: '',
		responsavel: '',
		cep: '',
		logradouro: '',
		complemento: '',
		numero: '',
		bairro: '',
		estado: '',
		municipio: '',
		telefoneCelular: '',
		telefoneFixo: '',
		email: '',
		tipologia: undefined,
		tipologias: []
	});

	const [validacao, setValidacao] = useState<any>({
		cpfCnpj: { valido: true, mensagem: null } as Validacao,
		razaoSocial: { valido: true, mensagem: null } as Validacao,
		nomeFantasia: { valido: true, mensagem: null } as Validacao,
		responsavel: { valido: true, mensagem: null } as Validacao,
		cep: { valido: true, mensagem: null } as Validacao,
		logradouro: { valido: true, mensagem: null } as Validacao,
		numero: { valido: true, mensagem: null } as Validacao,
		bairro: { valido: true, mensagem: null } as Validacao,
		estado: { valido: true, mensagem: 'Selecione o Estado' } as Validacao,
		municipio: { valido: true, mensagem: 'Selecione o Município' } as Validacao,
		email: { valido: true, mensagem: null } as Validacao,
		tipologias: { valido: true, mensagem: '' } as Validacao
	});

	const [EnderecoPesquisado, setEnderecoPesquisado] = useState<boolean>(false);
	const [EnderecoNaoEncontrado, setEnderecoNaoEncontrado] = useState<boolean>(false);
	const [municipios, setMunicipios] = useState<Municipio[]>([]);
	const [tipologias, setTipologias] = useState<Tipologia[]>([]);

	const { Padrao, Informacao } = useMensagem();
	const { BloquearTela, DesbloquearTela } = useEspera();
	const {
		cores: { INFO }
	} = useTema();

	const enabled = !EnderecoPesquisado || (EnderecoPesquisado && !EnderecoNaoEncontrado);

	const ehArrayTipologiasValidacao = Array.isArray(validacao.tipologias);

	const PesquisarPorCEP = () => {
		const cepSemFormatacao = formulario.cep.replace(/\D/gim, '');
		if (!cepSemFormatacao) {
			Padrao(
				'Atenção',
				`Informe um CEP para pesquisar.`,
				[
					{
						label: 'fechar',
						fechar: true
					}
				]
			);
			return;
		}

		BloquearTela();
		ObterEnderecoPorCEP(cepSemFormatacao)
			.pipe(
				tap((dados: DadosFormulario) => {
					setEnderecoNaoEncontrado(!!dados?.cep);
					setEnderecoPesquisado(true);
				}),
				switchMap((dados: DadosFormulario) => {
					if (!dados?.cep)
						throw new Error('CEP não encontrado');
					return of(dados);
				}),
			).subscribe({
				next: (dados: DadosFormulario) => {
					_set({
						cep: dados?.cep,
						logradouro: dados?.logradouro,
						bairro: dados?.bairro,
						complemento: dados?.complemento,
						estado: UF.ufId(dados?.estado.toString()),
						municipio: dados?.municipio,
					}, true);
				},
				error: (error: Error) => Padrao(
					'Busca pelo CEP',
					error.message, [{
						label: 'Ok',
						fechar: true,
						onPress: () => DesbloquearTela()
					}]),
				complete: () => DesbloquearTela()
			});
	};

	const LimparPesquisarCep = () => {
		setEnderecoNaoEncontrado(false);
		setEnderecoPesquisado(false);
		_set({
			cep: '',
			logradouro: '',
			bairro: '',
			complemento: '',
			estado: '',
			municipio: ''
		});
	};

	const _set = (obj: any, validar: boolean = false) => {
		setFormulario((form: DadosFormulario) => ({ ...form, ...obj } as DadosFormulario));

		if (validar)
			for (const [campo, valor] of Object.entries(obj))
				handleValidarValorCampo(campo as keyof DadosFormulario, valor);
	};

	const PopularSelectMunicipios = (selectedValue?: number | string) => {
		if (!formulario.estado) {
			setMunicipios([]);
			return;
		}
		const municipios = Municipios?.filter(({ uf }) => uf?.ufID == formulario.estado);
		setMunicipios(municipios);
		if (selectedValue) _set({ municipio: +selectedValue });
	};

	const variant = useMemo<TextFieldVariants>(() => 'standard', []);

	useEffect(() => PopularSelectMunicipios(), [formulario.estado]);

	useEffect(() => {
		from(TipologiaService.getAll())
			.pipe(
				switchMap((retorno: any | Error) => {
					if (retorno instanceof Error)
						return throwError(() => retorno);

					return of(retorno.data.map((tipologia: any) => new Tipologia(tipologia)));
				}),
			)
			.subscribe({
				next: (tipologias: Tipologia[]) => setTipologias(tipologias),
				error: (error) => ToastErro(error || 'Erro ao obter tipologias')
			});
	}, []);

	const handleRazaoSocial = (event: { target: { value: string }; }) => _set({ razaoSocial: event.target.value });
	const handleNomeFantasia = (event: { target: { value: string }; }) => _set({ nomeFantasia: event.target.value });
	const handleResponsavel = (event: { target: { value: string }; }) => _set({ responsavel: event.target.value });
	const handleCpfCnpj = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
			if (value.length <= 6)
				formattedValue = value.replace(/(\d{3})(\d{1,3})/g, '$1.$2');
			else if (value.length <= 9)
				formattedValue = value.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3');
			else if (value.length <= 10)
				formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4');
			_set({ cpfCnpj: formattedValue });
		} else if (value.length <= 14) {
			let formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g, '$1.$2.$3/$4-$5');
			if (value.length <= 12)
				formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1,4})/g, '$1.$2.$3/$4');
			_set({ cpfCnpj: formattedValue });
		}
	};
	const handleLogradouro = (event: { target: { value: string }; }) => _set({ logradouro: event.target.value });
	const handleCep = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 8) {
			const formattedValue = value.replace(/(\d{5})(\d{1,3})/g, '$1-$2');
			_set({ cep: formattedValue });
		}
	};
	const handleNumero = (event: { target: { value: string }; }) => _set({ numero: event.target.value });
	const handleBairro = (event: { target: { value: string }; }) => _set({ bairro: event.target.value });
	const handleComplemento = (event: { target: { value: string }; }) => _set({ complemento: event.target.value });
	const handleMunicipio = (event: { target: { value: string }; }) => _set({ municipio: event.target.value });
	const handleEstado = (event: { target: { value: string }; }) => _set({ estado: event.target.value });
	const handleTelefoneCelular = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{2})(\d{2,5})(\d{1,4})/g, '($1) $2-$3');
			if (value.length <= 10)
				formattedValue = value.replace(/(\d{2})(\d{2,4})(\d{1,4})/g, '($1) $2-$3');
			_set({ telefoneCelular: formattedValue });
		}
	};
	const handleTelefoneFixo = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{2})(\d{2,5})(\d{1,4})/g, '($1) $2-$3');
			if (value.length <= 10)
				formattedValue = value.replace(/(\d{2})(\d{2,4})(\d{1,4})/g, '($1) $2-$3');
			_set({ telefoneFixo: formattedValue });
		}
	};
	const handleEmail = (event: { target: { value: string }; }) => _set({ email: event.target.value });
	const handleTipologia = (novoValor: Tipologia | null) => _set({ tipologia: novoValor });
	const handleAtualizarCamposDaLista = (tipologiaID: number, unidadeTempoID?: number, tempo?: string | number) => {
		for (const _tipologia of formulario.tipologias) {
			if (_tipologia.tipologiaID == tipologiaID) {
				_tipologia.unidadeTempoID = !unidadeTempoID ? unidadeTempoID : unidadeTempoID as EUnidadeTempo;
				_tipologia.tempo = tempo;
			}
		}
		_set({ tipologias: [...formulario.tipologias] }, true);
	};


	const handleValidarCampo = (campo: keyof DadosFormulario) => {
		setValidacao((validados: any) => ({ ...validados, ...ValidadorEmpresa.validarCampo(formulario[campo], campo) }));
	};

	const handleValidarValorCampo = (campo: keyof DadosFormulario, valor: any): Validacao => {
		const campoValidado = ValidadorEmpresa.validarCampo(valor, campo);
		setValidacao((validados: any) => ({ ...validados, ...campoValidado }));
		return campoValidado;
	};

	const AdicionarTipologia = () => {
		if (!formulario.tipologia) {
			setValidacao((validados: any) => ({ ...validados, tipologia: { valido: false, mensagem: 'Selecione uma tipologia para ser adicionada' } as Validacao }));
			return;
		}
		const tipologiaJaAdicionada = formulario.tipologias.find(tipologia => tipologia.tipologiaID == formulario.tipologia?.tipologiaID);
		if (tipologiaJaAdicionada)
			tipologiaJaAdicionada.situacaoID = ESituacao.ATIVO;
		else
			formulario.tipologias?.push(formulario.tipologia);

		_set({ tipologias: [...formulario.tipologias], tipologia: null }, false);
	};

	const RemoverTipologia = (tipologia: Tipologia) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja remover a tipologia <Typography fontWeight={700}>&nbsp;{tipologia.nome}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						if (tipologia.tipologiaEmpresaID) {
							tipologia.situacaoID = ESituacao.INATIVO;
							_set({ tipologias: [...formulario.tipologias] });
						} else {
							const tipologiasRestantes = formulario.tipologias.filter((_tipologia) => _tipologia != tipologia);
							_set({ tipologias: [...tipologiasRestantes] });
						}
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	};

	const AlterarEmpresa = () => {
		const camposValidados = ValidadorEmpresa.validar(formulario);
		setValidacao(camposValidados);

		if (ValidadorEmpresa.ehValido(camposValidados)) {

			Informacao(
				null,
				() => <Typography display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
					Deseja prosseguir com o cadastro da empresa?
					<Typography fontWeight={600} fontSize={13} fontStyle='italic'>* Antes de confirmar, revise os dados informados.</Typography>
				</Typography>,
				[
					{
						label: 'Sim',
						onPress: () => {
							const alteracaoEmpresa = new EmpresaRequest({
								empresaID: empresaID,
								nomeFantasia: formulario.nomeFantasia,
								razaoSocial: formulario.razaoSocial,
								responsavel: formulario.responsavel,
								logradouro: formulario.logradouro,
								numero: formulario.numero,
								complemento: formulario.complemento,
								cpfcnpj: formulario.cpfCnpj?.replace(/\D/g, ''),
								cep: formulario.cep?.replace(/\D/g, ''),
								bairro: formulario.bairro,
								municipioID: formulario.municipio,
								ufid: formulario.estado,
								telefoneCelular: formulario.telefoneCelular?.replace(/\D/g, ''),
								telefoneFixo: formulario.telefoneFixo?.replace(/\D/g, ''),
								email: formulario.email,
								tipologiasEmpresa: formulario.tipologias
							});

							BloquearTela();
							from(EmpresaService.update(alteracaoEmpresa))
								.pipe(
									tap(() => DesbloquearTela()),
									switchMap((resposta) => {
										if (resposta instanceof Error)
											return throwError(() => resposta.message);
										return of(resposta);
									})
								)
								.subscribe({
									next: () => ToastSucesso(`Empresa alterada com sucesso`),
									error: (erro) => ToastErro(erro),
									complete: () => navigate('/empresa')
								});
						}
					},
					{
						label: 'Não',
						fechar: true
					}
				]
			);
		}
	};

	useEffect(() => PopularSelectMunicipios(), [formulario.estado]);

	useEffect(() => {
		BloquearTela();
		from(EmpresaService.getById(empresaID))
			.pipe(
				switchMap((resposta) => {
					if (resposta instanceof Error)
						return throwError(() => resposta.message);
					return of({
						cpfCnpj: resposta.cpfcnpj?.formatarCPFOuCNPJ(),
						razaoSocial: resposta.razaoSocial,
						nomeFantasia: resposta.nomeFantasia,
						responsavel: resposta.responsavel,
						cep: resposta.cep?.formatarCEP(),
						logradouro: resposta.logradouro,
						complemento: resposta.complemento,
						numero: resposta.numero,
						bairro: resposta.bairro,
						estado: resposta.municipio.ufid,
						municipio: resposta.municipio.municipioID,
						telefoneCelular: resposta.telefoneCelular?.formatarTelefone(),
						telefoneFixo: resposta.telefoneFixo?.formatarTelefone(),
						email: resposta.email,
						tipologias: resposta.tipologiasEmpresa.map(tipologiaEmpresa => new Tipologia(tipologiaEmpresa))
					});
				}),
				tap(() => DesbloquearTela()),
			).subscribe({
				next: (formulario) => {
					setFormulario(formulario);
					setEnderecoNaoEncontrado(true);
					setEnderecoPesquisado(true);
				},
				error: (erro) => ToastErro(erro)
			});
	}, []);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Alterar Empresa
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<LineGrid>
							<FormGrid size={{ xs: 12, md: 4 }}>
								<TextField
									id='cpfcnpj'
									name='cpfcnpj'
									type='text'
									placeholder='CPF/CNPJ'
									autoComplete='CPF/CNPJ'
									required
									value={formulario.cpfCnpj}
									onChange={handleCpfCnpj}
									disabled={true}
									label='CPF/CNPJ'
									helperText={validacao.cpfCnpj.mensagem}
									error={!validacao.cpfCnpj.valido}
									variant={variant}
									onBlur={() => handleValidarCampo('cpfCnpj')}
									size='small'
								/>
							</FormGrid>
						</LineGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<TextField
								id='razao-social'
								name='razao-social'
								type='text'
								placeholder='Razão Social'
								autoComplete='Razão Social'
								required
								value={formulario.razaoSocial}
								onChange={handleRazaoSocial}
								label='Razão Social'
								helperText={validacao.razaoSocial.mensagem}
								error={!validacao.razaoSocial.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('razaoSocial')}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<TextField
								id='nome-fantasia'
								name='nome-fantasia'
								type='text'
								placeholder='Nome Fantasia'
								autoComplete='Nome Fantasia'
								required
								value={formulario.nomeFantasia}
								onChange={handleNomeFantasia}
								label='Nome Fantasia'
								helperText={validacao.nomeFantasia.mensagem}
								error={!validacao.nomeFantasia.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('nomeFantasia')}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<TextField
								id='responsavel'
								name='responsavel'
								type='text'
								placeholder='Responsável'
								autoComplete='Responsável'
								required
								value={formulario.responsavel}
								onChange={handleResponsavel}
								label='Responsável'
								helperText={validacao.responsavel.mensagem}
								error={!validacao.responsavel.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('responsavel')}
								size='small'
							/>
						</FormGrid>
						<LineGrid>
							<FormGrid size={{ xs: 6, md: 2 }}>
								<TextField
									id='cep'
									name='cep'
									type='text'
									placeholder='00000-000'
									autoComplete='CEP'
									required
									value={formulario.cep}
									onChange={handleCep}
									label='CEP'
									disabled={!enabled}
									helperText={validacao.cep.mensagem}
									error={!validacao.cep.valido}
									variant={variant}
									onBlur={() => handleValidarCampo('cep')}
									size='small'
								/>
							</FormGrid>
							<FormGrid size={{ xs: 6, md: 3 }}>
								{
									enabled &&
									<Button
										color='info'
										disableElevation
										variant='contained'
										onClick={() => PesquisarPorCEP()}
										size='large'
										sx={{ mt: .5, ml: .5 }}
										startIcon={<Icon>search</Icon>}>
										<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
											Pesquisar
										</Typography>
									</Button>
								}
								{
									!enabled &&
									<Button
										color='inherit'
										disableElevation
										variant='contained'
										onClick={() => LimparPesquisarCep()}
										size='large'
										sx={{ mt: .5, ml: .5 }}
										startIcon={<Icon>close</Icon>}>
										<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
											Limpar Pesquisa
										</Typography>
									</Button>
								}
							</FormGrid>
						</LineGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<TextField
								id='logradouro'
								name='logradouro'
								type='text'
								placeholder='Logradouro'
								autoComplete='Logaradouro'
								required
								value={formulario.logradouro}
								onChange={handleLogradouro}
								label='Logradouro'
								disabled={!enabled}
								helperText={validacao.logradouro.mensagem}
								error={!validacao.logradouro.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('logradouro')}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 2 }}>
							<TextField
								id='numero'
								name='numero'
								type='text'
								placeholder='Número'
								autoComplete='Número'
								required
								value={formulario.numero}
								onChange={handleNumero}
								label='Número'
								helperText={validacao.numero.mensagem}
								error={!validacao.numero.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('numero')}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 4 }}>
							<TextField
								id='bairro'
								name='bairro'
								type='text'
								placeholder='Bairro'
								autoComplete='Bairro'
								required
								value={formulario.bairro}
								onChange={handleBairro}
								label='Bairro'
								disabled={!enabled}
								helperText={validacao.bairro.mensagem}
								error={!validacao.bairro.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('bairro')}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<TextField
								id='complemento'
								name='complemento'
								type='text'
								placeholder='Complemento'
								autoComplete='Complemento'
								value={formulario.complemento}
								onChange={handleComplemento}
								label='Complemento'
								variant={variant}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 2 }}>
							<TextField
								id='estado'
								name='estado'
								type='number'
								placeholder='Estado'
								autoComplete='Estado'
								required
								value={formulario.estado}
								onChange={handleEstado}
								label='Estado'
								disabled={!enabled}
								select
								defaultValue=''
								slotProps={{
									select: {
										native: true,
									},
								}}
								helperText={validacao.estado.mensagem}
								error={!validacao.estado.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('estado')}
								size='small'
							>
								<option key={9999} value=''>&nbsp;</option>
								{UFs.map(({ ufID, nome }) =>
									<option key={ufID} value={ufID}>
										<Typography sx={{ marginX: 5 }}>{nome}</Typography>
									</option>
								)}
							</TextField>
						</FormGrid>
						<FormGrid size={{ xs: 6, md: 3 }}>
							<TextField
								id='municipio'
								name='municipio'
								type='number'
								placeholder='Município'
								autoComplete='Município'
								required
								value={formulario.municipio}
								onChange={handleMunicipio}
								label='Município'
								disabled={!municipios?.length || !enabled}
								select
								defaultValue=''
								slotProps={{
									select: {
										native: true,
									},
								}}
								helperText={validacao.municipio.mensagem}
								error={!validacao.municipio.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('municipio')}
								size='small'
							>
								<option key={9999} value=''>&nbsp;</option>
								{municipios.map(({ municipioID, nome }) =>
									<option key={municipioID} value={municipioID}>
										<Typography mx={2}>{nome}</Typography>
									</option>
								)}
							</TextField>
						</FormGrid>
						<FormGrid size={{ xs: 4, md: 3 }}>
							<TextField
								id='telefone-celular'
								name='telefone-celular'
								type='text'
								placeholder='Telefone Celular'
								autoComplete='Telefone Celular'
								value={formulario.telefoneCelular}
								onChange={handleTelefoneCelular}
								label='Telefone Celular'
								variant={variant}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 4, md: 3 }}>
							<TextField
								id='telefone-fixo'
								name='telefone-fixo'
								type='text'
								placeholder='Telefone fixo'
								autoComplete='Telefone fixo'
								value={formulario.telefoneFixo}
								onChange={handleTelefoneFixo}
								label='Telefone Fixo'
								variant={variant}
								size='small'
							/>
						</FormGrid>
						<FormGrid size={{ xs: 4, md: 6 }}>
							<TextField
								id='email'
								name='email'
								type='email'
								placeholder='E-mail'
								autoComplete='E-mail'
								required
								value={formulario.email}
								onChange={handleEmail}
								label='E-mail'
								helperText={validacao.email.mensagem}
								error={!validacao.email.valido}
								variant={variant}
								onBlur={() => handleValidarCampo('email')}
								size='small'
							/>
						</FormGrid>
					</Grid>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ my: 3 }}
					>
						<Typography variant='h4'>
							Tipologias Empresa
						</Typography>
					</Grid>
					<Grid size={12}>
						<LineGrid>
							<FormGrid size={{ xs: 10 }}>
								<Autocomplete
									clearOnEscape
									value={formulario.tipologia}
									inputValue={formulario.tipologia?.nome || ''}
									options={tipologias}
									onChange={(event: any, novoValor: Tipologia | null) => handleTipologia(novoValor)}
									getOptionLabel={(option: Tipologia) => option.nome}
									getOptionDisabled={(option) =>
										formulario.tipologias?.some(({ tipologiaID, situacaoID }) => tipologiaID == option.tipologiaID && situacaoID == ESituacao.ATIVO) || false
									}
									renderInput={(params) =>
										<TextField
											{...params}
											id='tipologia'
											name='tipologia'
											type='text'
											placeholder='Tipologias'
											autoComplete='Tipologias'
											required
											label='Tipologias'
											defaultValue=''
											helperText={!ehArrayTipologiasValidacao ? validacao.tipologias.mensagem : null}
											error={!ehArrayTipologiasValidacao ? !validacao.tipologias.valido : false}
											variant={variant}
											onSelect={() => handleValidarCampo('tipologia')}
											size='small' />
									}
								/>
							</FormGrid>
							<FormGrid size={{ xs: 2 }}>
								<Button
									color='success'
									disableElevation
									variant='contained'
									onClick={() => AdicionarTipologia()}
									size='large'
									sx={{ mt: .5, ml: .5 }}
									startIcon={<Icon>add</Icon>}>
									<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
										Adicionar
									</Typography>
								</Button>
							</FormGrid>
						</LineGrid>
						<Grid size={12}>
							<List
								sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden' }}
								component="nav"
								aria-labelledby="lista-tipologia"
								subheader={
									<ListSubheader sx={{ px: 1, borderRadius: 2, width: '100%' }} id="lista-tipologia">
										Tipologias
									</ListSubheader>
								}
								dense>
								{
									formulario.tipologias
										?.filter(tipologia => tipologia.situacaoID == ESituacao.ATIVO)
										.map((tipologia) => {
											const { tipologiaID, nome, unidadeTempoID, tempo } = tipologia;
											let unidadeTempoValidacao = { valido: true, mensagem: null } as Validacao;
											let tempoValidacao = { valido: true, mensagem: null } as Validacao;
											if (ehArrayTipologiasValidacao) {
												const validacaoTipologia = validacao.tipologias.find((validacaoTipologia: ValidacaoTipologia) => validacaoTipologia.tipologiaID == tipologiaID);
												unidadeTempoValidacao = validacaoTipologia?.unidadeTempoID ?? { valido: true, mensagem: '' } as Validacao;
												tempoValidacao = validacaoTipologia?.tempo ?? { valido: true, mensagem: null } as Validacao;
											}
											return (
												<ListItem
													key={tipologiaID}
													sx={{ px: 1 }}>
													<LineGrid
														display='flex'
														flexDirection='row'
														justifyContent='center'
														alignItems='center'
														boxShadow='2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
														borderRadius={5}
														bgcolor={INFO.opacidade(15)}
														py={1}
													>
														<Grid size={.5} display='flex' alignSelf='center' justifyContent='center'>
															<DefaultButtonOnolyIcon icon='close' onClick={() => RemoverTipologia(tipologia)} />
														</Grid>
														<Grid size={8} display='flex' alignItems='center'>
															<Typography>
																{nome}
															</Typography>
														</Grid>
														<Grid size={2}>
															<TextField
																id='unidadeTempo'
																name='unidadeTempo'
																type='number'
																placeholder='Unidade Tempo'
																autoComplete='Unidade Tempo'
																required
																value={unidadeTempoID}
																onChange={(event) => handleAtualizarCamposDaLista(tipologiaID, +event.target.value, tempo)}
																label='Unidade Tempo'
																select
																slotProps={{
																	select: {
																		native: true,
																	},
																}}
																helperText={unidadeTempoValidacao?.mensagem}
																error={!unidadeTempoValidacao?.valido}
																variant={variant}
																size='small'
															>
																<option key={9999} value=''>&nbsp;</option>
																{unidadesTempo.map(({ unidadeTempoID, nome }) =>
																	<option key={unidadeTempoID} value={unidadeTempoID}>
																		<Typography sx={{ marginX: 5 }}>{nome}</Typography>
																	</option>
																)}
															</TextField>
														</Grid>
														<Grid size={2}>
															<TextField
																id='tempo'
																name='tempo'
																type='text'
																placeholder='Tempo'
																autoComplete='Tempo'
																required
																value={tempo}
																onChange={(event) => handleAtualizarCamposDaLista(tipologiaID, unidadeTempoID, event.target.value?.replace(/\D/g, ''))}
																label='Tempo'
																helperText={tempoValidacao?.mensagem}
																error={!tempoValidacao?.valido}
																variant={variant}
																//onBlur={() => handleValidarCampo('tempo')}
																size='small'
															/>
														</Grid>
													</LineGrid>
												</ListItem>
											);
										})
								}
							</List>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate('/empresa')}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
				<Button
					color='success'
					disableElevation
					variant='contained'
					onClick={() => AlterarEmpresa()}
					startIcon={<Icon>save</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						salvar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};
