import { FileOrigin, FileStatus } from 'filepond';

export type ODocumento = Blob & { readonly lastModified: number; readonly name: string; };
export class Documento {
	documentoID: string;
	serverId: string;
	source: ODocumento | string;
	origin: FileOrigin;
	status: FileStatus;
	file: ODocumento;
	fileExtension: string;
	fileSize: number;
	fileType: string;
	filename: string;
	filenameWithoutExtension: string;
	documentoEletronicoID: number;
	hashNome: string;

	constructor(dto?: any) {
		this.documentoID = dto?.id || dto?.documentoID;
		this.serverId = dto?.serverId;
		this.source = dto?.source;
		this.origin = dto?.origin;
		this.status = dto?.status;
		this.file = dto?.file;
		this.fileExtension = dto?.fileExtension;
		this.fileSize = dto?.fileSize;
		this.fileType = dto?.fileType;
		this.filename = dto?.filename;
		this.filenameWithoutExtension = dto?.filenameWithoutExtension;
		this.documentoEletronicoID = dto?.documentoEletronicoID;
		this.hashNome = dto?.hashNome;
	}
}